import React from 'react';
import styled from 'styled-components';

import { Text } from '../config/default-styles';

const FlexWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const StatusBadge = styled.div`
  padding: 0.5rem;
  background-color: ${props => props.theme.colors.background.secondary};
  border-radius: 0.25rem;
`;

const StatusBadgeSlim = styled(StatusBadge)`
  padding: 0.25rem 0.5rem;
`;

const StatusIndicator = styled.div`
  height: 0.5rem;
  width: 0.5rem;
  border-radius: 0.25rem;
  margin-right: 0.5rem;

  &.warning {
    background-color: ${props => props.theme.colors.status.warning};
  }

  &.success {
    background-color: ${props => props.theme.colors.status.success};
  }

  &.error {
    background-color: ${props => props.theme.colors.status.error};
  }

  &.progress {
    background-color: ${props => props.theme.colors.status.progress};
  }

  &.disabled {
    background-color: ${props => props.theme.colors.status.disabled};
  }
`;

type Props = {
  status: string;
  displayStatus: string;
  className?: string;
};

const Indicator = (props: Props): JSX.Element => (
  <StatusBadge>
    <FlexWrapper>
      <StatusIndicator className={props.status} />
      <Text>{props.displayStatus}</Text>
    </FlexWrapper>
  </StatusBadge>
);

export const IndicatorSlim = ({
  status,
  displayStatus,
  ...rest
}: Props): JSX.Element => (
  <StatusBadgeSlim {...rest}>
    <FlexWrapper>
      <StatusIndicator className={status} />
      <Text>{displayStatus}</Text>
    </FlexWrapper>
  </StatusBadgeSlim>
);

export default Indicator;
