import React from 'react';
import styled from 'styled-components';

import { Text, TextBold, H2, H3 } from '../../config/default-styles';
import { IndicatorSlim } from '../Indicators';
import PeopleBadgeComponent from '../Badges';
import { Status, Levels, ValueType } from '../../types';
import { getDaysLeft } from './utils';
import { PartialType } from './';

const getAssignmentStatusClass = (status: string): string => {
  const s = status.toLowerCase();
  if (
    s === Status.Draft ||
    s === Status.Onhold ||
    s === Status.Reserved ||
    s === Status.UnsignedDraft
  ) {
    return Levels.Warning;
  }

  if (s === Status.Onsale || s === Status.Onrent) {
    return Levels.Ok;
  }

  if (s === Status.Sold || s === Status.Ended || s === Status.Rented) {
    return Levels.Disabled;
  }

  throw Error(status);
};

const renderText = (text: string) => (
  <Text key={text.replace(/\s/, '-').toLowerCase()}>{text}</Text>
);

const Paragraph = styled.p`
  margin: 0 0 0.5em;

  &:last-child {
    margin-bottom: 0;
  }
`;

const StatusIndicator = styled(IndicatorSlim)`
  flex-shrink: 0;
`;
const PeopleBadge = styled(PeopleBadgeComponent)`
  flex-shrink: 0;
`;

const Datetime = styled.div``;
const HeaderDatetime = styled(Datetime).attrs({ as: 'p' })`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 0.75rem;
  color: ${p => p.theme.colors.text.secondary};
  margin: 0;
`;

const TableCell = styled.div`
  @media (max-width: ${p => p.theme.break.max}) {
    &:only-child {
      font-weight: 700;
    }

    &:after {
      content: attr(data-label);
      display: inline-block;
      margin-left: 0.3em;
      font-weight: 400;
    }

    &[data-content-only] {
      > * {
        display: none;
      }
      &:before {
        content: attr(data-value);
        font-weight: 700;
        display: inline-block;
      }
    }
  }
`;

const MobileLabel = styled(Paragraph.withComponent(Text))`
  @media (min-width: ${p => p.theme.break.min}) {
    display: none;
  }
`;

const Subtitle = TableCell.withComponent(TextBold.withComponent(Paragraph));
const TextBlock = TableCell.withComponent(Text.withComponent(Paragraph));
const Title = styled(H3)(
  ({ theme }) => `
  margin: 0;

  @media (min-width: ${theme.break.min}) {
    font-size: .875rem;

    &:not(:last-child) {
      margin: 0 0 .75em;
    }
  }
`,
);

const RewardText = styled(Title.withComponent(H2)).attrs({ as: 'span' })``;

const Row = styled(TextBlock)`
  display: flex;
  flex-direction: row;
  align-items: center;

  & + * {
    text-align: left;
  }
`;

const MobileHeader = styled.div(
  ({ theme }) => `
  @media (max-width: ${theme.break.max}) {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    &:not:last-child {
      margin-bottom: .5em;
    }
  }
`,
);

const Reward = styled(Text.withComponent('p'))(
  ({ theme }) => `
  display: flex;
  flex-direction: column;
  margin: 0;

  @media (min-width: ${theme.break.min}) {
    & + ${Datetime} {
      display: none;
    }
  }
`,
);

const TargetText = MobileLabel.withComponent('p');

const Address = styled.div(
  ({ theme }) => `
  display: flex;
  flex-direction: column-reverse;

  @media (min-width: ${theme.break.min}) {
    flex-direction: column;

    ${Datetime} {
      margin: auto 0 auto .5em;
    }
  }

  @media (max-width: ${theme.break.max}) {
    ${Datetime} {
      display: none;
    }
  }
`,
);

const ContactsPartial = ({ contacts: { text } }: PartialType) => (
  <TextBlock data-label="yhteydenottoa">{getText(text)}</TextBlock>
);

const ViewsPartial = ({ viewsInPortals: { text } }: PartialType) => (
  <TextBlock data-label="katselua">{getText(text)}</TextBlock>
);

const getText = (text: string) => (text !== '-' ? text : '0');

const DefaultPartial = (props: any) => (
  <>
    {Object.keys(props).map((cKey: string) =>
      renderText(getText(props[cKey].text)),
    )}
  </>
);

const AddressPartial = ({ address, status, endDate, id }: PartialType) => (
  <Address>
    <Title>{address.text}</Title>
    <Row as="div">
      <MobileHeader>
        <TargetText>
          Kohdenumero <TextBold>{id.text}</TextBold>
        </TargetText>
        <StatusIndicator
          status={getAssignmentStatusClass(status.text)}
          displayStatus={status.text}
        />
      </MobileHeader>
      {endDate.text !== '-' && (
        <HeaderDatetime>
          {getDaysLeft(endDate.text) ? (
            <Text>
              Päättyy&nbsp; {endDate.text}, {getDaysLeft(endDate.text)}
              pv&nbsp;jäljellä
            </Text>
          ) : (
            <Text>Päättyi&nbsp; {endDate.text}</Text>
          )}
        </HeaderDatetime>
      )}
    </Row>
  </Address>
);

const isNumValue = ({ num }: ValueType): boolean => !!parseInt(num.toString());

const DisplaysPartial = ({
  publicDisplays,
  peopleCount,
  privateDisplays,
  realtorPublicDisplays,
  realtorPeopleCount,
  realtorPrivateDisplays,
}: PartialType) => (
  <>
    <Row>
      <TextBold>{publicDisplays.text}</TextBold>&nbsp;Yleistä
      <PeopleBadge count={peopleCount.text} />
      {isNumValue(realtorPeopleCount) || isNumValue(realtorPublicDisplays)
        ? ` (${realtorPublicDisplays.text} / ${realtorPeopleCount.text})`
        : ''}
    </Row>
    <Subtitle>
      {privateDisplays.text}
      <Text>
        &nbsp;Yksityistä
        {isNumValue(realtorPrivateDisplays)
          ? ` (${realtorPrivateDisplays.text})`
          : ''}
      </Text>
    </Subtitle>
  </>
);

const OffersPartial = ({ tradeMethod, offers, realtorOffers }: PartialType) => (
  <TextBlock data-label="tarjousta" data-value={offers.num} data-content-only>
    <span>
      {tradeMethod.text}&nbsp;
      <strong>
        {offers.text}kpl
        {isNumValue(realtorOffers) ? ` (${realtorOffers.text})` : ''}
      </strong>
    </span>
  </TextBlock>
);

const RewardPartial = ({ reward, endDate }: PartialType) => {
  let datetime = null;
  if (endDate?.text !== '-') {
    const daysLeft = getDaysLeft(endDate.text);
    datetime = (
      <Datetime>
        {daysLeft ? (
          <Row>
            <TextBold>{daysLeft}pv</TextBold>
            <Text>&nbsp;jäljellä</Text>
          </Row>
        ) : null}
        <Row>
          <Text>{daysLeft ? 'Päättyy' : 'Päättyi'}&nbsp;</Text>
          <TextBold>{endDate.text}</TextBold>
        </Row>
      </Datetime>
    );
  }
  return (
    <MobileHeader>
      <Reward>
        <MobileLabel>Palkkio</MobileLabel>
        <RewardText>
          {reward.num.toLocaleString('fi-FI', {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
            style: 'currency',
            currency: 'EUR',
          })}
        </RewardText>
      </Reward>
      {datetime}
    </MobileHeader>
  );
};

export {
  renderText,
  ViewsPartial,
  DefaultPartial,
  ContactsPartial,
  AddressPartial,
  DisplaysPartial,
  OffersPartial,
  RewardPartial,
};
