import React from 'react';
import styled from 'styled-components';
import { useAuth0 } from '../../react-auth0-spa';
import { stopImpersonating, isImpersonating } from './utils';

const Title = styled.p`
  font-weight: 500;
  font-size: 0.9rem;
`;
const Button = styled.button.attrs({ type: 'button' })`
  background: ${({ theme: { colors } }) => colors.text.primary};
  color: ${({ theme: { colors } }) => colors.text.white};
  margin-left: 1rem;
  padding: 0.25em 0.5em;
  line-height: 1.45;
  display: block;
  font-weight: 600;
  border-radius: 0.25em;
  flex-shrink: 0;
  font-size: 0.875rem;
`;

const Banner = styled.section`
  padding: 0.5em 1em;
  width: 100%;
  color: ${({ theme: { colors } }) => colors.text.primary};
  background: ${({ theme: { colors } }) => colors.background.warning};
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: inset 0 -6px 8px -5px rgba(0, 0, 0, 0.25);
  line-height: 1.45;
`;

const ImpersonatorDisclaimer = ({ claims }: { claims: any }) => {
  const { user } = useAuth0();

  if (!user || !claims?.__raw || !isImpersonating(user)) {
    return null;
  }

  return (
    <Banner>
      <Title>Olet tekeytynyt käyttäjäksi {user.email}</Title>
      <Button
        onClick={async e => {
          e.preventDefault();
          await stopImpersonating({ claims });
        }}
      >
        Lopeta
      </Button>
    </Banner>
  );
};

export default ImpersonatorDisclaimer;
