import React from 'react';

import MeterBlock from '../../MeterBlock';
import { MeterPrimaryWithProfile } from '../../MeterPrimary';
import { MeterSecondary, MeterHrSecondary } from '../../MeterSecondary';
import { getTrend, getTrendPercent } from '../../Trends';
import { Props } from './index';

const StaffMobile = (props: Props): JSX.Element => {
  const { staff, hideStaff } = props;
  const objs = hideStaff ? staff.slice(0, 3) : staff;
  return (
    <>
      {objs.map(m => (
        <MeterBlock key={m.realtor.text}>
          <MeterPrimaryWithProfile
            {...{
              image: null,
              name: m.realtor.text,
              primary: m.rewards.num.toLocaleString('fi-FI', {
                style: 'currency',
                currency: 'EUR',
              }),
              secondary: `${m.salesPcs.num
                .toFixed(2)
                .replace('.', ',')
                .replace(',00', '')} kpl`,
              tertiary: 'myynnistä',
              legend: 'Palkkiotuotot',
              trendPercent: getTrendPercent(
                m.rewards.num,
                m.rewardsLastYear.num,
              ),
              trend: getTrend(m.rewards.num, m.rewardsLastYear.num),
            }}
          />
          <MeterSecondary
            {...{
              currentPeriod: m.calls.text,
              legend: 'hankintapuhelua',
              trendPercent: getTrendPercent(m.calls.num, m.callsLastYear.num),
              trend: getTrend(m.calls.num, m.callsLastYear.num),
            }}
          />
          <MeterHrSecondary />
          <MeterSecondary
            {...{
              currentPeriod: m.homeVisits.text,
              legend: 'kotikäyntiä',
              trendPercent: getTrendPercent(
                m.homeVisits.num,
                m.homeVisitsLastYear.num,
              ),
              trend: getTrend(m.homeVisits.num, m.homeVisitsLastYear.num),
            }}
          />
          <MeterHrSecondary />
          <MeterSecondary
            {...{
              currentPeriod: m.acquisitions.text,
              legend: 'toimeksiantoa',
              trendPercent: getTrendPercent(
                m.acquisitions.num,
                m.acquisitionLastYear.num,
              ),
              trend: getTrend(m.acquisitions.num, m.acquisitionLastYear.num),
            }}
          />
          <MeterHrSecondary />
          <MeterSecondary
            {...{
              currentPeriod: m.publicDisplays.text,
              legend: 'yleisesittelyä',
              trendPercent: getTrendPercent(
                m.publicDisplays.num,
                m.publicDisplaysLastYear.num,
              ),
              trend: getTrend(
                m.publicDisplays.num,
                m.publicDisplaysLastYear.num,
              ),
            }}
          />
          <MeterHrSecondary />
          <MeterSecondary
            {...{
              currentPeriod: m.privateDisplays.text,
              legend: 'yksityisesittelyä',
              trendPercent: getTrendPercent(
                m.privateDisplays.num,
                m.privateDisplaysLastYear.num,
              ),
              trend: getTrend(
                m.privateDisplays.num,
                m.privateDisplaysLastYear.num,
              ),
            }}
          />
        </MeterBlock>
      ))}
    </>
  );
};

export default StaffMobile;
