import styled from 'styled-components';

export default styled.button.attrs(({ type }: any) => ({
  type: type || 'button',
}))`
  max-width: 20rem;
  margin: 0 1.5rem;
  padding: 1rem;
  border-radius: 0.25rem;
  width: 100%;
  font-weight: bold;
  font-size: 0.875rem;
  color: ${props => props.theme.colors.text.white};
  border: ${props => props.theme.colors.border.primary} 1px solid;
  background: ${props => props.theme.colors.background.primary};
  transition: opacity 0.2s ease-in;

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;
