import { useAuth0 } from '../react-auth0-spa';

interface RedirectProps {
  claims: any;
}

const complainers = 'VALITTAJAT';

const Redirect = ({ claims }: RedirectProps): null => {
  const { user } = useAuth0();

  if (!user || !claims) {
    return null;
  }

  const userGroups = claims['https://kiinteistomaailma.fi/groups'];

  if (!userGroups || !userGroups.length) {
    console.warn('No AD Group detected');
  }
  const groups = (userGroups || [complainers]).map((g: string) =>
    g.toUpperCase(),
  );

  if (groups.includes('KETJU')) {
    window.location.href = '/chain';
    return null;
  }

  if (groups.includes('YRITTAJAT')) {
    window.location.href = '/entrepreneur';
    return null;
  }

  if (groups.includes(complainers)) {
    window.location.href = '/realtor';
    return null;
  }

  console.warn('No groups present!');
  window.location.href = '/realtor';

  return null;
};

export default Redirect;
