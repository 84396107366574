import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { ThemeProvider } from 'styled-components';
import Modal from 'react-modal';
import { Auth0Provider } from './react-auth0-spa';
import * as serviceWorker from './serviceWorker';
import App from './App';
import history from './utils/history';
import theme from './config/theme';
import getConfig from './config';
import GlobalStyle from '../src/config/global-style';

// A function that routes the user to the right place
// after login
const onRedirectCallback = (appState: any) => {
  history.push(
    appState && appState.targetUrl
      ? appState.targetUrl
      : window.location.pathname,
  );
};

const HOSTNAME = window.location.hostname;

const config = getConfig(HOSTNAME);

const { AUTH0_DOMAIN, AUTH0_CLIENT_ID, SENTRY_DSN, SENTRY_ENV } = config;

if (SENTRY_DSN) {
  Sentry.init({
    dsn: SENTRY_DSN,
    environment: SENTRY_ENV,
  });
}

ReactDOM.render(
  <Auth0Provider
    auth0Options={{
      domain: AUTH0_DOMAIN,
      client_id: AUTH0_CLIENT_ID,
      redirect_uri: window.location.origin,
    }}
    onRedirectCallback={onRedirectCallback}
  >
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <App />
    </ThemeProvider>
  </Auth0Provider>,
  document.getElementById('root'),
);

Modal.setAppElement('#root');

serviceWorker.unregister();
