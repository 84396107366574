const zipObj = (keys: any[], values: any[]) =>
  Object.assign({}, ...keys.map((k, i) => ({ [k]: values[i] })));

export const MONEY_FORMAT = {
  qDef: {
    qNumFormat: {
      qType: 'F',
      qnDec: 2,
      qUseThou: 0,
      // qFmt: '# ##0',
      qFmt: '# ##0,00 €', // OBS: &nbsp;
      qDec: ',',
      qThou: ' ',
    },
  },
};

const getKeys = (arr: string[][]) => arr.map(m => m[1]);

export const qlikHyperCube = (
  app: any,
  measures: string[][],
  format: any,
  cb: Function,
) => {
  const qMeasures = measures.map((m: string[]) => ({
    qLibraryId: m[0],
    ...format,
  }));

  app.createCube(
    {
      qDimensions: [],
      qMeasures,
      qInitialDataFetch: [
        {
          qTop: 0,
          qLeft: 0,
          qHeight: 200,
          qWidth: 10,
        },
      ],
    },
    (res: any) => {
      const measureKeys = getKeys(measures);
      const parsed = zipObj(
        measureKeys,
        res.qHyperCube.qGrandTotalRow.map((x: any) => ({
          num: x.qNum,
          text: x.qText,
        })),
      );
      cb(parsed);
    },
  );
};

type HcOptions = {
  qSuppressZero?: boolean;
  qSuppressMissing?: boolean;
};

export const qlikHyperCubeWithDimensions = (
  app: any,
  dimensions: string[][],
  measures: string[][],
  format: any,
  cb: Function,
  options: HcOptions = { qSuppressZero: true, qSuppressMissing: true },
) => {
  const qDimensions = dimensions.map((d: string[]) => ({
    qLibraryId: d[0],
    qNullSuppression: true,
  }));
  const qMeasures = measures.map((m: string[]) => ({
    qLibraryId: m[0],
    ...format,
  }));

  app.createCube(
    {
      qDimensions,
      qMeasures,
      qInitialDataFetch: [
        {
          qTop: 0,
          qLeft: 0,
          qHeight: 200,
          qWidth: 20,
        },
      ],
      qSuppressZero: options.qSuppressZero,
      qSuppressMissing: options.qSuppressMissing,
    },
    (res: any) => {
      const pages = res.qHyperCube.qDataPages;
      const rows = pages.map((page: any) => page.qMatrix).flat();
      const dimensionKeys = getKeys(dimensions);
      const measureKeys = getKeys(measures);

      const parsed = rows.map((row: any) => {
        const dimensions = zipObj(
          dimensionKeys,
          row.slice(0, dimensionKeys.length).map((x: any) => ({
            num: x.qNum,
            text: x.qText,
          })),
        );

        const measures = zipObj(
          measureKeys,
          row
            .slice(
              dimensionKeys.length,
              dimensionKeys.length + measureKeys.length,
            )
            .map((x: any) => ({
              num: x.qNum,
              text: x.qText,
            })),
        );

        return {
          ...dimensions,
          ...measures,
        };
      });

      cb(parsed);
    },
  );
};
