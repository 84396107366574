import React from 'react';
import styled from 'styled-components';

import Table, { Td, Th, Tr, TableBlock } from '../../Tables';
import { Tag, ShowMore } from '../../MeterBlock';
import { H2, Text, TextBold } from '../../../config/default-styles';
import { getHomeVisitStatusClass, Props, getDisplayStatus } from './index';
import { IndicatorSlim as StatusIndicator } from '../../Indicators';

const HeadingRow = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
  justify-content: space-between;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

type DesktopProps = {
  setHideHomeVisits: Function;
};

const HomeVisitsDesktop = (props: Props & DesktopProps): JSX.Element => {
  const { homeVisits, hideHomeVisits, setHideHomeVisits } = props;
  const objs = hideHomeVisits ? homeVisits.slice(0, 5) : homeVisits;
  return (
    <TableBlock style={{ marginTop: '1rem' }}>
      <HeadingRow>
        <Row>
          <H2>Kotikäynnit</H2>
          <Tag style={{ marginLeft: '1rem' }}>{homeVisits.length}</Tag>
        </Row>
        <div
          onClick={() => {
            setHideHomeVisits(!hideHomeVisits);
          }}
        >
          <TextBold>
            <ShowMore
              head={3}
              action={setHideHomeVisits}
              hideAll={hideHomeVisits}
              itemCount={homeVisits.length}
            />
          </TextBold>
        </div>
      </HeadingRow>
      <Table>
        <thead>
          <Tr>
            <Th>
              <Text>Osoite</Text>
            </Th>
            <Th>
              <Text>Asiakas</Text>
            </Th>
            <Th>
              <Text>Päivä</Text>
            </Th>
            <Th style={{ textAlign: 'right' }}>
              <Text>Tila</Text>
            </Th>
          </Tr>
        </thead>
        <tbody>
          {objs.map((h, idx) => (
            <Tr key={idx}>
              <Td>
                <TextBold>{h.address.text}</TextBold>
              </Td>
              <Td>
                <Text>{h.customer.text}</Text>
              </Td>
              <Td>
                <Text>{h.date.text}</Text>
              </Td>
              <Td style={{ textAlign: 'right' }}>
                <div style={{ display: 'inline-block' }}>
                  <StatusIndicator
                    status={getHomeVisitStatusClass(h.status.text)}
                    displayStatus={getDisplayStatus(h.status.text)}
                  />
                </div>
              </Td>
            </Tr>
          ))}
        </tbody>
      </Table>
    </TableBlock>
  );
};

export default HomeVisitsDesktop;
