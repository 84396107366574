import React from 'react';
import styled from 'styled-components';

import ProfileImage, { ProfileImageWithInitals } from './ProfileImage';
import {
  Text,
  TextBold as DefaultTextBold,
  H1,
  H2,
  H3,
  H4,
  Small,
} from '../config/default-styles';

import { TrendPrimary, TrendPrimaryNoBg } from './Trends';
import StatusIndicator from './Indicators';
import {
  getHomeVisitStatusClass,
  getDisplayStatus,
} from './Realtor/HomeVisits';

const WrapperPrimary = styled.div`
  display: flex;
  background: ${props => props.theme.colors.background.white};
  justify-content: space-between;
  padding: 1rem;
  align-items: center;
`;

const WrapperPrimarySummary = styled(WrapperPrimary)`
  background: #f9fafb;
`;

const LegendPrimary = styled.div`
  font-weight: normal;
  color: ${p => p.theme.colors.text.secondary};
  text-transform: uppercase;
  font-size: 0.6875rem;
  letter-spacing: 0.15rem;
`;

const LegendPrimary2 = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 0.875rem;
`;

const LeftPrimary = styled.div``;

const RightPrimary = styled.div`
  display: flex;
`;

const FlexWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

const Label = styled.span`
  margin-left: 0.5rem;
  border: 1px solid ${props => props.theme.colors.border.primary};
  border-radius: 0.25rem;
  padding: 0.25rem;
`;

export const Hr = styled.hr`
  border: 0.5px solid ${p => p.theme.colors.background.tertiary};
  margin: 0;
`;

const HrWrapperPrimary = styled.div`
  padding: 0rem 1rem;
  margin: 0;
`;

export const MeterHeading = styled.div`
  padding: 1.65rem 1rem;
  font-style: normal;
  font-weight: bold;
  font-size: 0.875rem;
`;

const TextBold = styled(DefaultTextBold)``;

export const MeterHrPrimary = () => (
  <HrWrapperPrimary>
    <Hr />
  </HrWrapperPrimary>
);

type Content = {
  legend: string;
  currentPeriod: string;
  trendPercent: string;
  trend: string;
};

const PrimaryContent = (props: Content) => (
  <>
    <LeftPrimary>
      <LegendPrimary>{props.legend}</LegendPrimary>
      <H1>{props.currentPeriod}</H1>
    </LeftPrimary>
    <RightPrimary>
      <TrendPrimary trendPercent={props.trendPercent} trend={props.trend} />
    </RightPrimary>
  </>
);

const ProfileWrapper = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 1rem;
`;

type ContentTwoLines = {
  legend: string;
  first: {
    primary: string;
    secondary: string;
    tertiary: string;
  };
  second: {
    primary: string;
    secondary: string;
    tertiary: string;
  };
  trendPercent: string;
  trend: string;
};

const PrimaryContentTwoLines = (props: ContentTwoLines) => (
  <>
    <LeftPrimary>
      <LegendPrimary>{props.legend}</LegendPrimary>
      <div style={{ marginTop: '1rem' }}>
        <H2 as="span">{props.first.primary}</H2>
        <H3 as="span"> {props.first.secondary}</H3>
        <Text as="span"> {props.first.tertiary}</Text>
      </div>
      <div style={{ marginTop: '1rem' }}>
        <H3 as="span">{props.second.primary}</H3>
        <H4 as="span"> {props.second.secondary}</H4>
        <Text as="span"> {props.second.tertiary}</Text>
      </div>
    </LeftPrimary>
    <RightPrimary>
      <TrendPrimary trendPercent={props.trendPercent} trend={props.trend} />
    </RightPrimary>
  </>
);

type ContentTwoLines2 = {
  legend: string;
  first: {
    primary: string;
    secondary: string;
  };
  second: {
    primary: string;
    secondary: string;
  };
  trendPercent: string;
  trend: string;
};

const PrimaryContentTwoLines2 = (props: ContentTwoLines2) => (
  <>
    <LeftPrimary>
      <LegendPrimary2>{props.legend}</LegendPrimary2>
      <div style={{ marginTop: '1rem' }}>
        <H1 as="span">{props.first.primary}</H1>
        <H4 as="span"> {props.first.secondary}</H4>
      </div>
      <div style={{ marginTop: '1rem' }}>
        <Text as="span">{props.second.primary}</Text>
        <H4 as="span"> {props.second.secondary}</H4>
      </div>
    </LeftPrimary>
    <RightPrimary>
      <TrendPrimaryNoBg trendPercent={props.trendPercent} trend={props.trend} />
    </RightPrimary>
  </>
);

export const MeterPrimaryHomeVisit = (props: {
  name: string;
  address: string;
  status: string;
  date: string;
}) => (
  <WrapperPrimary className="meter primary" style={{ borderRadius: '0.5rem' }}>
    <LeftPrimary>
      <Column>
        <Text>{props.name}</Text>
        <TextBold style={{ marginTop: '0.5rem' }}>{props.address}</TextBold>
      </Column>
    </LeftPrimary>
    <RightPrimary>
      <Column>
        <StatusIndicator
          status={getHomeVisitStatusClass(props.status)}
          displayStatus={getDisplayStatus(props.status)}
        />
        <Text style={{ marginTop: '3.3125rem', textAlign: 'right' }}>
          {props.date}
        </Text>
      </Column>
    </RightPrimary>
  </WrapperPrimary>
);

export const MeterPrimaryAssigmentBottom = (props: {
  reward: string;
  endDate: string;
  numDays: number;
}) => (
  <WrapperPrimary className="meter primary">
    <LeftPrimary>
      <Column>
        <Text>Odotettu palkkio</Text>
        <H2 style={{ marginTop: '0.5rem' }}>{props.reward}</H2>
      </Column>
    </LeftPrimary>
    <RightPrimary style={{ textAlign: 'right' }}>
      <Column>
        {props.endDate !== '-' && (
          <>
            <div style={{ marginBottom: '0.5rem' }}>
              <TextBold>{props.numDays}pv</TextBold> <Text>jäljellä </Text>
            </div>
            <div>
              <Text>Päättyy</Text> <TextBold>{props.endDate}</TextBold>
            </div>
          </>
        )}
      </Column>
    </RightPrimary>
  </WrapperPrimary>
);

export const MeterPrimaryWithProfile = (props: {
  image: string | null;
  name: string;
  legend: string;
  primary: string;
  secondary: string;
  tertiary: string;
  trendPercent: string;
  trend: string;
}) => (
  <WrapperPrimary className="meter primary">
    <LeftPrimary>
      <ProfileWrapper>
        {props.image && <ProfileImage image={props.image} />}
        {!props.image && <ProfileImageWithInitals name={props.name} />}
        <TextBold style={{ marginLeft: '1rem' }}>{props.name}</TextBold>
      </ProfileWrapper>
      <LegendPrimary style={{ marginBottom: '0.5rem' }}>
        {props.legend}
      </LegendPrimary>
      <H2 as="span">{props.primary}&nbsp;</H2>
      <H4 as="span">{props.secondary}&nbsp;</H4>
      <Text as="span">{props.tertiary}</Text>
    </LeftPrimary>
    <RightPrimary>
      <TrendPrimary trendPercent={props.trendPercent} trend={props.trend} />
    </RightPrimary>
  </WrapperPrimary>
);

export const MeterPrimaryWithLabel = (props: {
  legend: string;
  currentPeriod: string;
  trendPercent: string;
  trend: string;
  label: {
    primary: string;
    secondary: string;
  };
}) => (
  <WrapperPrimary className="meter primary">
    <LeftPrimary>
      <LegendPrimary>{props.legend}</LegendPrimary>
      <FlexWrapper>
        <H1>{props.currentPeriod}</H1>
        <Label>
          <Small>
            <strong>{props.label.primary}</strong> {props.label.secondary}
          </Small>
        </Label>
      </FlexWrapper>
    </LeftPrimary>
    <RightPrimary>
      <TrendPrimary trendPercent={props.trendPercent} trend={props.trend} />
    </RightPrimary>
  </WrapperPrimary>
);

export const MeterPrimaryTwoLinesSummary = (props: ContentTwoLines) => (
  <WrapperPrimary className="meter primary summary">
    <PrimaryContentTwoLines {...props} />
  </WrapperPrimary>
);

export const MeterPrimaryTwoLines = (props: ContentTwoLines) => (
  <WrapperPrimary className="meter primary">
    <PrimaryContentTwoLines {...props} />
  </WrapperPrimary>
);

export const MeterPrimaryTwoLines2 = (props: ContentTwoLines2) => (
  <WrapperPrimary className="meter primary">
    <PrimaryContentTwoLines2 {...props} />
  </WrapperPrimary>
);

export const MeterPrimaryTwoLinesSummary2 = (props: ContentTwoLines2) => (
  <WrapperPrimary className="meter primary summary">
    <PrimaryContentTwoLines2 {...props} />
  </WrapperPrimary>
);

export const MeterPrimarySummary = (props: Content) => (
  <WrapperPrimarySummary className="meter primary summary">
    <PrimaryContent {...props} />
  </WrapperPrimarySummary>
);

export const MeterPrimary = (props: Content) => (
  <WrapperPrimary className="meter primary">
    <PrimaryContent {...props} />
  </WrapperPrimary>
);
