import React from 'react';
import styled from 'styled-components';

import AreaFilter from '../AreaFilter';
import Table, { Th, Tr, TableBlock } from '../../Tables';
import { Text, H2, TextBold } from '../../../config/default-styles';
import { Props } from './index';
import { getTrend, getChangePercentStr } from './index';

const HeadingRow = styled.div`
  display: flex;
  align-items: center;
  margin-top: 1rem;
  margin-bottom: 2rem;
  justify-content: space-between;
`;

const Row = styled.div`
  height: 2rem;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const TdLargePadding = styled.td`
  padding: 1rem;
  border-bottom: 1px solid ${props => props.theme.colors.border.primary};
`;

const TrendText = styled(TextBold)`
  &.positive {
    color: #00a050;
  }

  &.negative {
    color: #c54f4f;
  }

  &.no-change {
    color: #78b4dc;
  }
`;

export const TdNoBorder = styled(TdLargePadding)`
  padding: 1rem;
`;

type PropsDesktop = {
  saveDefaultZipCodes: Function;
  setSelectedZipCodes: Function;
  setDefaultZipCodes: Function;
  defaultZipCodes: any;
  selectedZipCodes: any;
  marketAreaDisplayPeriod: string[];
};

const TdOld = (props: Props): JSX.Element => {
  return (
    <Tr>
      <TdNoBorder>
        <Text>Vanhat</Text>
      </TdNoBorder>
      <TdNoBorder>
        <Text>{getChangePercentStr(props.oldPercent.num)}</Text>
      </TdNoBorder>
      <TdNoBorder>
        <Text>{props.oldPcsKm.text} kpl</Text>
      </TdNoBorder>
      <TdNoBorder>
        <TrendText className={getTrend(props.oldChangePercent.num)}>
          {getChangePercentStr(props.oldChangePercent.num)}
        </TrendText>
      </TdNoBorder>
      <TdNoBorder>
        <TrendText className={getTrend(props.oldChangePcs.num)}>
          {props.oldChangePcs.num} kpl
        </TrendText>
      </TdNoBorder>
      <TdNoBorder
        style={{ textAlign: 'right', background: 'rgba(230, 235, 238, .25)' }}
      >
        <TextBold>{getChangePercentStr(props.chainOldPercnt.num)}</TextBold>
      </TdNoBorder>
    </Tr>
  );
};

const TdNew = (props: Props): JSX.Element => {
  return (
    <Tr>
      <TdLargePadding>
        <Text>Uudet</Text>
      </TdLargePadding>
      <TdLargePadding>
        <Text>{getChangePercentStr(props.newPercent.num)}</Text>
      </TdLargePadding>
      <TdLargePadding>
        <Text>{props.newPcsKm.text} kpl</Text>
      </TdLargePadding>
      <TdLargePadding>
        <TrendText className={getTrend(props.newChangePercent.num)}>
          {getChangePercentStr(props.newChangePercent.num)}
        </TrendText>
      </TdLargePadding>
      <TdLargePadding>
        <TrendText className={getTrend(props.newChangePcs.num)}>
          {props.newChangePcs.num} kpl
        </TrendText>
      </TdLargePadding>
      <TdLargePadding
        style={{ textAlign: 'right', background: 'rgba(230, 235, 238, .25)' }}
      >
        <TextBold>{getChangePercentStr(props.chainNewPercent.num)}</TextBold>
      </TdLargePadding>
    </Tr>
  );
};

const TdSummary = (props: Props): JSX.Element => {
  return (
    <Tr>
      <TdLargePadding>
        <TextBold>Yhteensä</TextBold>
      </TdLargePadding>
      <TdLargePadding>
        <TextBold>{getChangePercentStr(props.totalPercent.num)}</TextBold>
      </TdLargePadding>
      <TdLargePadding>
        <TextBold>{props.totalPcsKm.text} kpl</TextBold>
      </TdLargePadding>
      <TdLargePadding>
        <TrendText className={getTrend(props.totalChangePercent.num)}>
          {getChangePercentStr(props.totalChangePercent.num)}
        </TrendText>
      </TdLargePadding>
      <TdLargePadding>
        <TrendText className={getTrend(props.totalChangePcs.num)}>
          {props.totalChangePcs.num} kpl
        </TrendText>
      </TdLargePadding>
      <TdLargePadding
        style={{ textAlign: 'right', background: 'rgba(230, 235, 238, .25)' }}
      >
        <TextBold>{getChangePercentStr(props.chainTotalPercent.num)}</TextBold>
      </TdLargePadding>
    </Tr>
  );
};

const MarketShareDesktop = (props: Props & PropsDesktop): JSX.Element => {
  const {
    saveDefaultZipCodes,
    defaultZipCodes,
    selectedZipCodes,
    setSelectedZipCodes,
    setDefaultZipCodes,
    marketAreaDisplayPeriod,
  } = props;

  return (
    <TableBlock style={{ marginTop: '1rem' }}>
      <HeadingRow>
        <Row>
          <div>
            <H2>Markkinaosuus</H2>
          </div>
          <AreaFilter
            setSelectedZipCodes={setSelectedZipCodes}
            saveDefaultZipCodes={saveDefaultZipCodes}
            selectedZipCodes={selectedZipCodes}
            setDefaultZipCodes={setDefaultZipCodes}
            defaultZipCodes={defaultZipCodes}
          />
        </Row>
        <Row>
          <Text>
            ({marketAreaDisplayPeriod[0]} - {marketAreaDisplayPeriod[1]})
          </Text>
        </Row>
      </HeadingRow>
      <Table>
        <thead>
          <Tr>
            <Th>
              <Text>&nbsp;</Text>
            </Th>
            <Th>
              <Text>Osuus %</Text>
            </Th>
            <Th>
              <Text>Osuus kpl</Text>
            </Th>
            <Th>
              <Text>Muutos %</Text>
            </Th>
            <Th>
              <Text>Muutos kpl</Text>
            </Th>
            <Th style={{ textAlign: 'right' }}>
              <Text>Ketjun osuus</Text>
            </Th>
          </Tr>
        </thead>
        <tbody>
          <TdOld {...props} />
          <TdNew {...props} />
          <TdSummary {...props} />
        </tbody>
      </Table>
    </TableBlock>
  );
};

export default MarketShareDesktop;
