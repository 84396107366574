import React, { useState } from 'react';
import styled, { keyframes, css } from 'styled-components';
import Button from '../Button';
import { stopImpersonating, startImpersonate } from './utils';

interface FormProps {
  readonly isImpersonating: boolean;
}

const validateEmail = (mail: string) => /^[^@]+@[^@]+\.[^@]+$/.test(mail);

const FormSection = styled.div(
  ({ theme: { colors } }) => `
  padding: 1.5rem 1rem;

  &:first-of-type {
    background: ${colors.background.warning};
    color: ${colors.text.primary};
    border-radius: 0.5rem 0.5rem 0 0;
    position: relative;
  }
`,
);

const loaderAnimation = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const Loader = styled.div.attrs({ 'aria-hidden': 'true' })(
  ({ theme: { colors } }) => css`
    display: inline-block;
    margin: 0 auto 0 1.5rem;
    vertical-align: middle;
    width: 1.5rem;
    height: 1.5rem;

    &:after {
      content: '';
      display: block;
      width: inherit;
      height: inherit;
      margin: auto;
      border-radius: 50%;
      color: ${colors.background.warning};
      border: 0.15em solid currentColor;
      border-color: currentColor transparent currentColor transparent;
      animation: ${loaderAnimation} 1.2s linear infinite;
    }

    ${FormSection}:first-of-type & {
      &:after {
        color: ${colors.text.primary};
      }
    }
  `,
);

const Form = styled.form<FormProps>(
  ({ theme: { colors }, isImpersonating }) => `
  width: 100%;
  max-width: 22rem;
  margin: 2rem auto 1em;
  background: ${colors.background.site};
  color: ${colors.text.primary};
  padding: ${isImpersonating ? '0' : '1.5rem 1rem'};
  border-radius: 0.5rem;
  box-shadow: 0 -1px 9px 0px rgba(0, 0, 0, 0.4);
  position: relative;

  button {
    margin: 0 auto;
    font-size: 0.8rem;
    background: ${colors.background.warning};
    color: ${colors.text.primary};
    padding: 0.7rem 1rem;
    width: auto;
    border: 0;

    &[type="reset"] {
      background: ${colors.text.primary};
      color: ${colors.text.white};
    }
  }
`,
);

const Title = styled.h2`
  margin: 0 0 1.25rem;
  font-size: 1.4rem;
`;

const AltTitle = styled.h3`
  margin: 0 0 1.25rem;
  font-size: 1.125rem;
`;

const ErrorMsg = styled.p`
  color: ${({ theme: { colors } }) => colors.status.error};
`;

const Email = styled.input.attrs({ type: 'email' })`
  margin: 0 0 1rem;
  border: 1px solid rgba(0, 0, 0, 0.2);
  width: 100%;
  padding: 0.5em;
  transition: border-color 0.125s ease-in;

  &:active,
  &:focus {
    outline: 0;
    border-color: rgba(0, 0, 0, 0.4);
  }
`;

const EmailText = styled.span`
  font-size: 0.6em;
  word-break: break-word;
`;

const Label = styled.label`
  margin: 0 auto 0.25rem;
  width: 100%;
  display: block;
`;

const LabelText = styled.p`
  margin: 0 0 0.5em;
  font-size: 80%;
`;

const initialState = { loading: '', email: '', error: '' };

const Impersonate = ({
  isImpersonating,
  email: userEmail,
  claims,
}: {
  isImpersonating: boolean;
  email: string;
  claims: any;
}) => {
  const [state, setState] = useState(initialState);
  const onSubmit = async (event: any) => {
    event.preventDefault();
    setState({ ...state, ...{ loading: 'submit' } });
    const success = await startImpersonate({ ...state, claims });
    if (!success) {
      setState({ ...initialState, error: 'Failed to impersonate such user' });
    }
  };

  const onReset = async (event: any) => {
    event.preventDefault();
    setState({ ...state, ...{ loading: 'reset' } });
    stopImpersonating({ claims });
    // NOTE: dont need to await for success as it will happen via window.reload.
  };

  const onChange = ({ target: { value: email } }: { target: any }) => {
    setState({ ...state, ...{ email, error: '' } });
  };

  const title = isImpersonating ? (
    <>
      Olet tekeytynyt käyttäjäksi <EmailText>{userEmail}</EmailText>
    </>
  ) : (
    'Tekeydy toiseksi käyttäjäksi'
  );

  const primaryFormPart = (
    <>
      <Label>
        <LabelText>Syötä sähköposti</LabelText>
        <Email
          onChange={onChange}
          value={state.email}
          placeholder="etunimi.sukunimi@km.fi"
        />
      </Label>
      <Button
        type="submit"
        onClick={onSubmit}
        disabled={!validateEmail(state.email) || !!state.loading}
      >
        {isImpersonating ? 'Tekeydy toiseksi' : 'Aloita'}
      </Button>
      {state.loading === 'submit' && <Loader />}
      {state.error && <ErrorMsg>{state.error}</ErrorMsg>}
    </>
  );
  return (
    <Form isImpersonating={isImpersonating}>
      {isImpersonating ? (
        <>
          <FormSection>
            <Title>{title}</Title>
            <Button onClick={onReset} type="reset">
              Lopeta tekeytyminen
            </Button>
            {state.loading === 'reset' && <Loader />}
          </FormSection>
          <FormSection>
            <AltTitle>Tai vaihtoehtoisesti</AltTitle>
            {primaryFormPart}
          </FormSection>
        </>
      ) : (
        <>
          <Title>{title}</Title>
          {primaryFormPart}
        </>
      )}
    </Form>
  );
};

export default Impersonate;
