import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { breakpoint } from '../../../config/theme';
import { SortType, SortDir, SortingType, Status } from '../../../types';
import TableGrid, { GridCell, PartialType } from '../../TableGrid';
import {
  AddressPartial,
  OffersPartial,
  DisplaysPartial,
  RewardPartial,
  ContactsPartial,
  ViewsPartial,
  DefaultPartial,
  renderText,
} from '../../TableGrid/partials';
import Filters, { FilterType } from '../../TableGrid/filters';
import {
  sortItems,
  getPartialsSortBy,
  mapTableProps,
} from '../../TableGrid/utils';

type AssignmentProps = {
  onHide: Function;
  data: PartialType[];
  showAll: boolean;
  title?: string;
};

const DEFAULT_SORTING_KEY = 'status';
const DEFAULT_SORTING_DIRECTION = SortDir.asc;
const OTHER_SORTING_DIRECTION = SortDir.desc;

const getDefaultItemCount = () => (window.innerWidth > breakpoint - 1 ? 5 : 3);

interface StateType {
  data: PartialType[];
  filters: FilterType;
}

const defaultFilters: Readonly<FilterType> = { events: false };

// interface ConfigPart {
//   header: string,
//   dataFrom: string[] | never[]
// }
// type AssignmentsConfig = {
//   address: ConfigPart;
//   offers: ConfigPart;
//   contacts: ConfigPart;
//   endDate: ConfigPart;
//   reward: ConfigPart;
//   viewsInPortals: ConfigPart;
//   displays: ConfigPart;
// };

const tableConfig = {
  address: {
    header: 'Osoite',
    sortable: true,
    dataFrom: ['status', 'endDate', 'id'],
  },
  viewsInPortals: {
    header: 'Katselut',
    sortable: true,
    dataFrom: [],
  },
  // contacts: {
  //   header: 'Yhteydenotot',
  //   dataFrom: []
  // },
  displays: {
    header: 'Esittelyt (välittäjän)',
    dataFrom: [
      'publicDisplays',
      'peopleCount',
      'privateDisplays',
      'realtorPublicDisplays',
      'realtorPeopleCount',
      'realtorPrivateDisplays',
    ],
  },
  offers: {
    header: 'Kauppatapa ja tarjoukset (välittäjän)',
    dataFrom: ['tradeMethod', 'realtorOffers'],
  },
  reward: {
    header: 'Odotettu palkkio',
    sortable: true,
    dataFrom: ['endDate'],
  },
};

const renderCell = (props: PartialType, type: string) => {
  switch (type) {
    case 'address':
      return <AddressPartial {...props} />;
    case 'offers':
      return <OffersPartial {...props} />;
    case 'displays':
      return <DisplaysPartial {...props} />;
    case 'reward':
      return <RewardPartial {...props} />;
    case 'contacts':
      return <ContactsPartial {...props} />;
    case 'viewsInPortals':
      return <ViewsPartial {...props} />;
    default:
      return <DefaultPartial {...props} />;
  }
};
const StyledFilters = styled(Filters)``;

const StyledTableGrid = styled(TableGrid)(
  ({ theme }) => `
  & + & {
    margin-top: 1.5em;
  }
  @media (min-width: ${theme.break.min}) {
    ${GridCell} {
      &[data-type="viewsInPortals"],
      &[data-type="contacts"],
      &[data-type="offers"] {
        text-align: center;
        justify-content: center;
      }
    }

    ${StyledFilters} {
      margin: 0 auto 0 2.5rem;
    }
  }

  @media (max-width: ${theme.break.max}) {
    ${StyledFilters} {
      order: 1;
      width: 100%;
      margin: 0 0 1em;
      padding: .5em 0 0;
      border-top: 1px solid ${theme.colors.border.primary};
    }
  }
`,
);

const activityKeys = [
  'offers',
  'privateDisplays',
  'publicDisplays',
  'tradeMethod',
]; // 'contacts'
const activityStatuses = [Status.Sold, Status.Ended, Status.Rented];

/*
 * Filtering can be done by many things. This returns a function for filter based on the type.
 * Takes the object being filtered and the current state of filter.
 */
const getFilterFunc = (type: string): Function => {
  switch (type) {
    case 'events':
      return (obj: any, rule: boolean) => {
        const isActive =
          activityKeys.some(key => !!parseInt((obj[key] || {}).num, 10)) ||
          activityStatuses.includes(obj.status.text.toLowerCase());
        return rule ? isActive : !isActive;
      };
    default: {
      console.error(`No such filter type (${type}). Unable to filter.`);
      return () => false;
    }
  }
};

const applyFilters = (
  data: PartialType[],
  filters: FilterType,
): PartialType[] =>
  Object.keys(filters).reduce<any[]>(
    (acc: PartialType[], fKey: string): PartialType[] => [
      ...acc.filter((obj: PartialType) =>
        getFilterFunc(fKey)(obj, filters[fKey]),
      ),
    ],
    data,
  );

const Assignments = ({
  data,
  onHide,
  showAll,
  title = 'Toimeksiannot',
}: AssignmentProps) => {
  const [sorting, setSorting] = useState<SortType>({
    key: DEFAULT_SORTING_KEY,
    dir: DEFAULT_SORTING_DIRECTION,
    sortBy: SortingType.alphabetical,
  });
  const [state, setState] = useState<StateType>({
    data: [],
    filters: defaultFilters,
  });

  useEffect(() => {
    setState(s => ({
      ...s,
      data: [...applyFilters(data, s.filters)],
    }));
  }, [data]);

  if (!data) {
    return null;
  }

  const onFilter = (type: string) => {
    const filterFunction = getFilterFunc(type);
    const filterRule = !state.filters[type];

    setState(s => ({
      ...s,
      filters: {
        ...s.filters,
        [type]: filterRule,
      },
      data: [...data.filter((obj: any) => filterFunction(obj, filterRule))],
    }));
  };

  const sorted = [...sortItems(state.data, sorting)];
  const sliced = showAll ? sorted : sorted.slice(0, getDefaultItemCount());
  const onSort = (key: string) => {
    const { key: currKey, dir: currDir } = sorting;
    // Is default sorting
    if (
      currKey === DEFAULT_SORTING_KEY &&
      currDir === DEFAULT_SORTING_DIRECTION
    ) {
      setSorting({ ...sorting, key, sortBy: getPartialsSortBy(key) });
    } else {
      // If it's the same key and direction is changed, go back to default key. else just set the key given.
      // for direction if the to be key is the same as current, swap direction.
      const newKey =
        key === currKey && sorting.dir === OTHER_SORTING_DIRECTION
          ? DEFAULT_SORTING_KEY
          : key;
      setSorting({
        sortBy: getPartialsSortBy(newKey),
        key: newKey,
        dir:
          newKey === currKey
            ? OTHER_SORTING_DIRECTION
            : DEFAULT_SORTING_DIRECTION,
      });
    }
  };

  return (
    <StyledTableGrid
      gridGap={{ row: '.25rem', col: '.5rem' }}
      borderRadius="0.5rem"
      // gridTemplate="4fr 1fr 1fr 1fr 2fr 1fr"
      gridTemplate="4fr 1fr 1.25fr 1.5fr 1fr"
      noResults="Ei listattavia toimeksiantoja."
      renderHeaderCell={renderText}
      totalCount={state.data.length}
      onToggleDisplayMode={onHide}
      {...{ title, renderCell, showAll, onSort, sorting }}
      {...mapTableProps(sliced, tableConfig)}
    >
      <StyledFilters onFilter={onFilter} filters={state.filters} />
    </StyledTableGrid>
  );
};

export default Assignments;
