import moment from 'moment';

import zipCodes from '../../zip_codes.json';
import { Zip, GroupedZips } from '../../types';

// group the Zips by city
export const grouped = (zips: Zip[]): GroupedZips => {
  return zips.reduce((acc: any, curr: Zip) => {
    if (Object.keys(acc).includes(curr.city)) return acc;
    acc[curr.city] = zips.filter(group => group.city === curr.city);
    return acc;
  }, {});
};

// map the string zip code s to corresponding Zip Objects
export const mapZipStr = (zips: string[]): Zip[] => {
  const match: any = zips.map(z => zipCodes.find(x => x.zip === z));
  return match.filter((z: Zip) => z !== undefined);
};

// get the dates of the display period, which always lags behind
export const getMarketAreaDisplayPeriod = (start: Date, end: Date) => {
  const now = new Date();
  // depends on wether the latest information has arrived from last month
  // it's agreed upon that every 15th of month the infomation is ready
  const lastMonthDataArrived = now.getDate() >= 15;

  const maxEndDate = lastMonthDataArrived
    ? moment()
        .endOf('month')
        .subtract(1, 'months')
    : moment()
        .subtract(2, 'months')
        .endOf('month');

  const adjustedEnd =
    moment(end) > maxEndDate ? maxEndDate : moment(end).endOf('month');

  const minStartDate =
    moment(start) > adjustedEnd
      ? moment(adjustedEnd).startOf('month')
      : moment(start);
  const adjustedStart = minStartDate.startOf('month');

  return [adjustedStart.format('DD.MM.YYYY'), adjustedEnd.format('DD.MM.YYYY')];
};
