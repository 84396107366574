import React, { useState } from 'react';
import styled from 'styled-components';
import ClipLoader from 'react-spinners/ClipLoader';

import zipCodes from '../../zip_codes.json';
import {
  H3,
  TextBold as DefaultTextBold,
  Text as DefaultText,
} from '../../config/default-styles';
import { ReactComponent as Arrow } from '../../assets/left-arrow.svg';
import { ReactComponent as CloseIcon } from '../../assets/close.svg';
import { ReactComponent as PlusIcon } from '../../assets/plus.svg';
import { ReactComponent as Search } from '../../assets/search.svg';
import { ReactComponent as RemoveIcon } from '../../assets/remove.svg';
import { MeterBlockHeading } from '../MeterBlock';
import { grouped } from './utils';
import { Zip, GroupedZips } from '../../types';

const Wrapper = styled.div``;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
`;

const Body = styled.div`
  display: flex;
  justify-content: space-around;
  flex-direction: column;
`;

const Input = styled.input`
  border: none;
  margin: 0 1rem;
  height: 2.75rem;
  width: 100%;
  color: ${props => props.theme.colors.text.primary};
  ::placeholder {
    color: ${props => props.theme.colors.text.secondary};
  }

  &:focus {
    outline: none;
  }
`;

const InputWrapper = styled.div`
  border: 2px solid ${props => props.theme.colors.border.primary};
  height: 3rem;
  border-radius: 1.5rem;
  margin: 0 1rem;
  display: flex;
  align-items: center;
  padding: 0 1rem;
  &:focus-within {
    border: 2px solid ${props => props.theme.colors.border.ascent};
  }
`;

const Text = styled(DefaultText)`
  padding: 2rem 2.25rem 0 2rem;
  text-align: center;
  line-height: 150%;
  color: ${props => props.theme.colors.text.primary};
`;

const TextBold = styled(DefaultTextBold)`
  margin: 1rem;
`;

const GreenText = styled(DefaultTextBold)`
  margin: 1rem;
  color: ${props => props.theme.colors.text.green};
  cursor: pointer;
  min-width: 8rem;

  &.loading {
    cursor: default;
  }
`;

const AscentText = styled(DefaultTextBold)`
  color: ${props => props.theme.colors.border.ascent};
  font-size: 1rem;
  margin-right: 1rem;
`;

const RedText = styled(DefaultTextBold)`
  margin: 1rem;
  color: ${props => props.theme.colors.text.red};
  cursor: pointer;
  min-width: 8rem;
`;

const TextLight = styled(Text)`
  color: ${props => props.theme.colors.text.secondary};
`;

const Remove = styled(RemoveIcon)`
  width: 1rem;
  height: 1rem;
  cursor: pointer;
`;

const Plus = styled(PlusIcon)`
  width: 1rem;
  height: 1rem;
  cursor: pointer;
`;

const Close = styled(CloseIcon)`
  width: 1rem;
  height: 1rem;
  cursor: pointer;
`;

const SelectedTagWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 0.75rem;
`;

const SelectedTag = styled.div`
  display: flex;
  background-color: ${props => props.theme.colors.background.green};
  color: ${props => props.theme.colors.text.green};
  padding: 0 0.75rem;
  margin: 0.25rem;
  font-weight: bold;
  font-size: 0.8125rem;
  border-radius: 0.25rem;
  align-items: center;
  height: 2rem;
  cursor: pointer;
`;

const SelectControlsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Hr = styled.hr`
  width: 100%;
  border: 1px solid ${props => props.theme.colors.border.primary};
`;

const PrimaryHeading = styled(H3)``;
const SecondaryHeading = styled(MeterBlockHeading)`
  padding: 2rem 1rem;
  margin: 0;
`;

const Tag = styled.span`
  padding: 0.25rem;
  background-color: ${props => props.theme.colors.background.tertiary};
`;

const ResultWrapper = styled.div``;
const Result = styled.div``;
const City = styled.div``;
const CityRow = styled.div`
  display: flex;
  justify-content: space-between;
  color: ${props => props.theme.colors.text.green};
  font-size: 0.8125rem;
  font-weight: bold;
  padding: 0 1rem;
  margin-top: 1.5rem;
  align-content: center;
`;

const Areas = styled.ul`
  list-style: none;
`;

const AreaRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-content: center;
  padding-right: 1rem;
  margin-top: 1.5rem;
`;

const Area = styled.li`
  font-size: 0.8125rem;
`;

const AscentList = styled.ul`
  list-style: none;
  margin: 0rem;
  padding: 1rem;
`;

const AscentListItem = styled.li`
  line-height: 150%;
  font-size: 0.8125rem;
  ${props => props.theme.colors.text.primary};
`;

type HeadingProps = {
  children: string;
  tag: string | number;
};

const SecondaryHeadingWithTag = (props: HeadingProps): JSX.Element => (
  <SecondaryHeading style={{ padding: '1rem' }}>
    {props.children} <Tag>{props.tag}</Tag>
  </SecondaryHeading>
);

export type Props = {
  setIsOpen: Function;
  saveDefaultZipCodes: Function;
  setSelectedZipCodes: Function;
  setDefaultZipCodes: Function;
  selectedZipCodes?: Zip[];
};

const MarketAreaSelector = (props: Props): JSX.Element => {
  const {
    setIsOpen,
    saveDefaultZipCodes,
    setSelectedZipCodes,
    setDefaultZipCodes,
    selectedZipCodes = [],
  } = props;
  const [searchResult, setSearchResult] = useState<GroupedZips | {}>({});
  const [selected, setSelected] = useState<Zip[]>(selectedZipCodes);
  const [query, setQuery] = useState<string>('');
  const [isSaving, setIsSaving] = useState<boolean>(false);

  // search from Zip Objects
  const search = (q: string) => {
    const query = q.toLowerCase();
    setQuery(q);

    if (query.length === 0) {
      setSearchResult([]);
      return;
    }

    // avoid performance issues
    if (query.length < 2) {
      return;
    }

    const result = zipCodes.filter(
      obj =>
        obj.city.toLowerCase().includes(query) ||
        obj.name.toLowerCase().includes(query) ||
        obj.zip.toLowerCase().includes(query),
    );

    setSearchResult(grouped(result));
  };

  // utility functions for making selections
  const addZip = (zip: Zip) => {
    setSelected([...selected, zip]);
  };

  const removeZip = (zip: Zip) => {
    setSelected([...selected.filter(z => z.zip !== zip.zip)]);
  };

  const addCityZips = (city: string) => {
    const zipsInCity = zipCodes.filter(z => z.city === city);
    const notSelected = zipsInCity.filter(
      z => !selected.map(x => x.zip).includes(z.zip),
    );
    setSelected([...selected, ...notSelected]);
  };

  const removeCityZips = (city: string) => {
    const zipsInCity = zipCodes.filter(z => z.city === city).map(z => z.zip);
    setSelected([...selected.filter(z => !zipsInCity.includes(z.zip))]);
  };

  const allZipsInCitySelected = (city: string) => {
    const zipsInCity = zipCodes.filter(z => z.city === city).map(z => z.zip);
    return zipsInCity
      .map(z => selected.map(x => x.zip).includes(z))
      .every(elem => elem === true);
  };

  return (
    <Wrapper>
      <Header>
        <Arrow
          onClick={() => {
            setSelectedZipCodes(selected.map(z => z.zip));
            setIsOpen(false);
          }}
        />
        <PrimaryHeading>Rajaa aluetta</PrimaryHeading>
        <Close onClick={() => setIsOpen(false)} id="areafilter-close" />
      </Header>
      <Body>
        <InputWrapper>
          <Search />
          <Input
            placeholder="Rajaa kuntia, kaupunkeja tai postinumeroita"
            onChange={e => {
              e.preventDefault();
              search(e.target.value);
            }}
            value={query}
          />
        </InputWrapper>

        {!Object.keys(searchResult).length && (
          <Text>
            Syötä rajaus kunnan tai kaupungin nimellä. Voit tehdä tarkemman
            rajauksen syöttämällä yhden tai useamman postinumeron.
          </Text>
        )}

        {/* Empty */}
        {Object.keys(selected).length === 0 && query === '' && (
          <>
            <SecondaryHeading>Edelliset haut</SecondaryHeading>
            <Hr />
            <TextLight>Emme löytäneet hakuasi vastaavia kuntia</TextLight>
          </>
        )}

        {/* Selected */}
        {Object.keys(selected).length !== 0 && (
          <>
            <TextBold>Valitut alueet</TextBold>
            <SelectedTagWrapper>
              {Object.entries(grouped(selected)).map(([k, v]) => (
                <SelectedTag
                  key={k}
                  onClick={() => {
                    setQuery(k);
                    search(k);
                  }}
                >
                  {k} ({v.length})
                  <Remove
                    style={{ marginLeft: '0.75rem' }}
                    onClick={() => {
                      removeCityZips(k);
                    }}
                  />
                </SelectedTag>
              ))}
            </SelectedTagWrapper>
            <SelectControlsWrapper>
              <GreenText
                className={isSaving ? 'loading' : ''}
                onClick={async () => {
                  if (!isSaving) {
                    setIsSaving(true);
                    await saveDefaultZipCodes(selected);
                    setDefaultZipCodes(selected.map(z => z.zip));
                    setSelectedZipCodes(selected.map(z => z.zip));
                    setIsOpen(false);
                  }
                }}
              >
                <div style={{ textAlign: 'center' }}>
                  <ClipLoader size={32} color={'#006450'} loading={isSaving} />
                </div>
                {!isSaving && 'Tallenna oletukseksi'}
              </GreenText>

              <RedText
                onClick={() => {
                  setSelected([]);
                  setQuery('');
                  search('');
                }}
              >
                Poista rajaukset
              </RedText>
            </SelectControlsWrapper>
          </>
        )}

        {query !== '' && (
          <>
            <SecondaryHeadingWithTag tag={Object.keys(searchResult).length}>
              tulokset
            </SecondaryHeadingWithTag>
            <Hr />
          </>
        )}

        {Object.keys(searchResult).length === 0 && query !== '' && (
          <>
            <Text style={{ textAlign: 'left', padding: '1rem' }}>
              Emme löytäneet hakuasi vastaavia kuntia, kaupunkeja tai
              postinumeroita.
            </Text>
            <AscentList>
              <AscentListItem>
                <AscentText>&bull;</AscentText> Tarkista ettei haussasi ole
                kirjoitusvirheitä
              </AscentListItem>
              <AscentListItem>
                <AscentText>&bull;</AscentText> Koita toista hakua eri kunnan
                tai kaupugin nimellä tai postinumerolla.
              </AscentListItem>
            </AscentList>
          </>
        )}

        {/* Search results */}
        <ResultWrapper>
          {Object.entries(searchResult).map(([k, v]) => (
            <Result key={k}>
              <CityRow>
                <City>{k}</City>
                {allZipsInCitySelected(k) && (
                  <Remove onClick={() => removeCityZips(k)} />
                )}
                {!allZipsInCitySelected(k) && (
                  <Plus onClick={() => addCityZips(k)} />
                )}
              </CityRow>
              {v.map((zip: Zip) => (
                <Areas key={zip.zip}>
                  <AreaRow>
                    <Area>
                      {zip.zip} &mdash; {zip.name}
                    </Area>
                    {selected.map(z => z.zip).includes(zip.zip) && (
                      <Remove onClick={() => removeZip(zip)} />
                    )}
                    {!selected.map(z => z.zip).includes(zip.zip) && (
                      <Plus onClick={() => addZip(zip)} />
                    )}
                  </AreaRow>
                </Areas>
              ))}
            </Result>
          ))}
        </ResultWrapper>
      </Body>
    </Wrapper>
  );
};

export default MarketAreaSelector;
