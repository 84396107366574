import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  padding: 1rem 5rem 1rem 5rem;
`;

const Pre = styled.pre`
  background: ${$props => $props.theme.colors.background.primary};
  color: white;
  padding: 1rem;
`;

type Props = {
  app: any;
};

type QItem = {
  qData: {
    title: string;
  };
  qInfo: {
    qId: string;
  };
  qName: string;
};

type Response = {
  qMeasureList: {
    qItems: QItem[];
  };
  qDimensionList: {
    qItems: QItem[];
  };
  qFieldList: {
    qItems: QItem[];
  };
};

export default (props: Props): JSX.Element | null => {
  const [measures, setMeasures] = useState<string[]>([]);
  const [dimensions, setDimensions] = useState<string[]>([]);
  const [fields, setFields] = useState<string[]>([]);

  const { app } = props;

  if (!app) {
    return null;
  }

  useEffect(() => {
    if (!measures.length) {
      app.getList('MeasureList', (resp: Response) => {
        const parsed = resp?.qMeasureList.qItems.map(
          (x: QItem) => `"${x.qData.title}": "${x.qInfo.qId}"`,
        );
        setMeasures(parsed);
      });
    }
  }, [app, measures]);

  useEffect(() => {
    if (!dimensions.length) {
      app.getList('DimensionList', (resp: Response) => {
        const parsed = resp?.qDimensionList.qItems.map(
          (x: QItem) => `"${x.qData.title}": "${x.qInfo.qId}"`,
        );
        setDimensions(parsed);
      });
    }
  }, [app, dimensions]);

  useEffect(() => {
    if (!fields.length) {
      app.getList('FieldList', (resp: Response) => {
        const parsed = resp?.qFieldList.qItems.map((x: QItem) => `${x.qName}`);
        setFields(parsed);
      });
    }
  }, [app, fields]);

  return (
    <Wrapper>
      <h1>Development Documentation</h1>
      <p>
        This is the documentation for fetching the Qlik sense Measurements and
        Dimensions. The Mesurements' and Dimensions' ids must be fetched through
        and API.
      </p>
      Measures
      <Pre>
        {JSON.stringify(measures, null, 2)
          .replace(/\\/g, '')
          .replace(/""/g, '"')}
      </Pre>
      Dimensions
      <Pre>
        {JSON.stringify(dimensions, null, 2)
          .replace(/\\/g, '')
          .replace(/""/g, '"')}
      </Pre>
      Fields
      <Pre>
        {JSON.stringify(fields, null, 2)
          .replace(/\\/g, '')
          .replace(/""/g, '"')}
      </Pre>
    </Wrapper>
  );
};
