import moment from 'moment';
import { Status, SortType, SortingType } from '../../types';

const getDaysLeft = (date: string): number => {
  const daysLeft = moment(date, 'DD.M.YYYY').diff(moment(), 'days');
  if (daysLeft < 0) {
    return 0;
  }
  return daysLeft;
};

const getPartialsSortBy = (type: string): SortingType => {
  switch (type) {
    case 'reward':
      return SortingType.numeric;
    case 'viewsInPortals':
      return SortingType.numeric;
    default:
      return SortingType.alphabetical;
  }
};

const sortNumeric = (a: number, b: number): number =>
  (Number.isNaN(a) ? 0 : a) - (Number.isNaN(b) ? 0 : b);

const sortAlphabetic = (a: string, b: string): number => {
  if (a.toUpperCase() < b.toUpperCase()) {
    return -1;
  }
  if (a.toUpperCase() > b.toUpperCase()) {
    return 1;
  }
  return 0;
};

const sortByType = (a: any, b: any, { key, dir, sortBy }: SortType): number => {
  const aProp = (dir === 'ASC' ? a : b)[key];
  const bProp = (dir === 'ASC' ? b : a)[key];
  switch (sortBy) {
    case 'numeric':
      return sortNumeric(parseInt(aProp.num), parseInt(bProp.num));
    case 'alphabetical':
      return sortAlphabetic(aProp.text, bProp.text);
    default:
      throw Error(`Not a known sorting type: ${sortBy}`);
  }
};

const sortItems = (items: any[], sorting: SortType): any[] => {
  // sort by attention needed by the user
  if (!sorting || sorting.key === 'status') {
    const sorted = [
      ...items.filter(x => x.status.text.toLowerCase() === Status.Draft),
      ...items.filter(x => x.status.text.toLowerCase() === Status.Onhold),
      ...items.filter(x => x.status.text.toLowerCase() === Status.Reserved),
      ...items.filter(
        x => x.status.text.toLowerCase() === Status.UnsignedDraft,
      ),
      ...items.filter(x => x.status.text.toLowerCase() === Status.Onsale),
      ...items.filter(x => x.status.text.toLowerCase() === Status.Onrent),
      ...items.filter(x => x.status.text.toLowerCase() === Status.Sold),
      ...items.filter(x => x.status.text.toLowerCase() === Status.Ended),
      ...items.filter(x => x.status.text.toLowerCase() === Status.Rented),
    ];

    // incase status missing
    return [...sorted, ...items.filter(x => !sorted.includes(x))];
  }

  return [...items.sort((a, b) => sortByType(a, b, sorting))]; //, 'alphabetical'))];
};

function isValid<T>(arr: T, key: any): key is keyof T {
  return typeof arr[key as keyof T] !== 'undefined';
}

// type ConfigProp = {
//   header: string;
//   dataFrom: string[];
// }
// interface ConfigProps {
//   // keyOf all the data we can have: ConfigProp;
// }

/*
 * mapTableProps gets an array of data rows. Data rows are mapped based on config.
 * This allows combining different data cells into single table cells.
 * It returns an object with header and rows, where header is generated based on config values and rows match those
 */
const mapTableProps = (arr: any[], config: any) => ({
  header: Object.keys(config)
    .filter(Boolean)
    .map(key =>
      isValid(config, key)
        ? {
            text: config[key].header,
            type: key,
            sortable: config[key].sortable,
          }
        : { text: '', type: 'unknown' },
    ),
  rows: arr
    .map((ass: any) =>
      Object.keys(config)
        .filter(Boolean)
        .map(key => {
          if (isValid(config, key)) {
            const { dataFrom = [] } = config[key];
            // tähän ne loput, samanlainen typeguard sinne assille
            return {
              type: key,
              props: dataFrom.length
                ? dataFrom.reduce(
                    (acc: any, dataKey: string) => ({
                      ...acc,
                      ...{ [dataKey]: ass[dataKey] },
                    }),
                    ass[key] ? { [key]: ass[key] } : {},
                  )
                : { [key]: ass[key] },
            };
          }
          return { type: 'undefined', props: {} };
        }),
    )
    .filter(Boolean),
});

export { getDaysLeft, sortItems, getPartialsSortBy, mapTableProps };
