import { MeterProp } from '../../../types';

enum Status {
  Active = 'active',
  Completed = 'completed',
  CompleteOnlyEstimate = 'completed_only_estimate',
  CompletedLost = 'completed_lost',
  CompletedWon = 'completed_won',
}

enum Levels {
  Warning = 'warning',
  Disabled = 'disabled',
  Progress = 'progress',
  Error = 'error',
  Success = 'success',
}

export const getHomeVisitStatusClass = (status: string) => {
  const s = status.toLowerCase();
  switch (s) {
    case Status.Active:
      return Levels.Warning;
    case Status.Completed:
    case Status.CompletedWon:
      return Levels.Success;
    case Status.CompleteOnlyEstimate:
      return Levels.Progress;
    case Status.CompletedLost:
      return Levels.Error;
  }
  return Levels.Disabled;
};

export const getDisplayStatus = (status: string): string => {
  const s = status.toLowerCase();
  if (s === Status.Active) {
    return 'Avoinna';
  }
  if (s === Status.Completed) {
    return 'Suoritettu (vanhat)';
  }
  if (s === Status.CompleteOnlyEstimate) {
    return 'Arviokäynti';
  }
  if (s === Status.CompletedLost) {
    return 'Menetetty kilpailijalle';
  }
  if (s === Status.CompletedWon) {
    return 'Voitettu';
  }
  return 'Tuntematon';
};

export type HomeVisit = {
  address: MeterProp;
  customer: MeterProp;
  status: MeterProp;
  date: MeterProp;
};

export type Props = {
  homeVisits: [HomeVisit];
  hideHomeVisits: boolean;
};

export { default as HomeVisitsMobile } from './Mobile';
export { default as HomeVisitsDesktop } from './Desktop';
