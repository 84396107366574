const URL = `https://${window.location.host}/api/impersonate`;
const impersonatorGroups = ['ketjuohjaus-henkilökunta', 'webmaster'];

const createAuthHeaders = (claims: any) => ({
  headers: {
    Authorization: `Bearer ${claims?.__raw}`,
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
  },
});

const fetchAndReload = async (payload: any) => {
  const req = await fetch(URL, payload);
  if (req.ok) {
    window.location.href = window.location.origin;
  } else {
    throw new Error(
      `Request error: ${req.statusText} from status ${req.status}.`,
    );
  }
};

export const stopImpersonating = async ({ claims }: any) => {
  // call delete endpoint
  try {
    await fetchAndReload({
      method: 'DELETE',
      ...createAuthHeaders(claims),
    });
    return true;
  } catch (e) {
    console.warn(`Couldn't stop impersonating.`, e);
    return null;
  }
};

const smartResolveEmail = (email: string) =>
  /@km.fi$/.test(email)
    ? email.replace(/@km.fi$/, '@kiinteistomaailma.fi')
    : email;

export const startImpersonate = async ({
  email,
  claims,
}: {
  email: string;
  claims: any;
}) => {
  // call post endpoint
  try {
    await fetchAndReload({
      method: 'POST',
      body: JSON.stringify({ email: smartResolveEmail(email) }),
      ...createAuthHeaders(claims),
    });
    return true;
  } catch (e) {
    console.warn(`Couldn't start impersonating. `, e);
    return null;
  }
};

export const isImpersonating = (user: any) =>
  !!user['https://kiinteistomaailma.fi/impersonate'];

const isDev = () =>
  window.location.port ||
  window.location.search.indexOf('debugImpersonate') > -1;
const sanitizeGroup = (g: string) => g.toLowerCase().replace(/\s/g, '-');
export const canImpersonate = (user: any) =>
  isDev() ||
  isImpersonating(user) ||
  (user['https://kiinteistomaailma.fi/groups'] || []).filter((group: string) =>
    impersonatorGroups.includes(sanitizeGroup(group)),
  ).length;
