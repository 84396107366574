import React from 'react';

import MeterBlock from '../../MeterBlock';
import {
  MeterPrimaryTwoLines2,
  MeterPrimaryTwoLinesSummary2,
} from '../../MeterPrimary';
import { getChangePercentStr, Props } from './index';
import { getTrend } from './index';

const getTrendFromPcsOrPercent = (percent: number, pcs: number) => {
  if (isNaN(percent)) {
    return getTrend(pcs);
  }
  return getTrend(percent);
};

const MarketShareMobile = (props: Props): JSX.Element => {
  return (
    <MeterBlock>
      <MeterPrimaryTwoLines2
        {...{
          first: {
            primary: `${getChangePercentStr(props.oldPercent.num)}`,
            secondary: `${props.oldPcsKm.text} kpl`,
          },
          second: {
            primary: 'Ketjun markkinaosuus alueella',
            secondary: `${getChangePercentStr(props.chainOldPercnt.num)}`,
          },
          trend: getTrendFromPcsOrPercent(
            props.oldChangePercent.num,
            props.oldChangePcs.num,
          ),
          trendPercent: `${getChangePercentStr(props.oldChangePercent.num)} / ${
            props.oldChangePcs.num
          } kpl`,
          legend: 'Vanhat',
        }}
      />
      <MeterPrimaryTwoLines2
        {...{
          first: {
            primary: `${getChangePercentStr(props.newPercent.num)}`,
            secondary: `${props.newPcsKm.text} kpl`,
          },
          second: {
            primary: 'Ketjun markkinaosuus alueella',
            secondary: `${getChangePercentStr(props.chainNewPercent.num)}`,
          },
          trend: getTrendFromPcsOrPercent(
            props.newChangePercent.num,
            props.newChangePcs.num,
          ),
          trendPercent: `${getChangePercentStr(props.newChangePercent.num)} / ${
            props.newChangePcs.num
          } kpl`,
          legend: 'Uudiskohteet',
        }}
      />
      <MeterPrimaryTwoLinesSummary2
        {...{
          first: {
            primary: `${getChangePercentStr(props.totalPercent.num)}`,
            secondary: `${props.totalPcsKm.text} kpl`,
          },
          second: {
            primary: 'Ketjun markkinaosuus alueella',
            secondary: `${getChangePercentStr(props.chainTotalPercent.num)}`,
          },
          trend: getTrendFromPcsOrPercent(
            props.totalChangePercent.num,
            props.totalChangePcs.num,
          ),
          trendPercent: `${getChangePercentStr(
            props.totalChangePercent.num,
          )} / ${props.totalChangePcs.num} kpl`,
          legend: 'Kaikki',
        }}
      />
    </MeterBlock>
  );
};

export default MarketShareMobile;
