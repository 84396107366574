import React, { useState, useEffect } from 'react';
import { Router, Switch, Route } from 'react-router-dom';
import moment from 'moment';

import { useAuth0 } from './react-auth0-spa';
import history from './utils/history';
import { config as defaultQlikConfig } from './config/qlik-config';
import { getConfig } from './config';
import QlikApp from './services/qlik-app';

import Redirect from './components/Redirect';
import Documentation from './components/Documentation';
import PrivateRoute from './components/PrivateRoute';
import Entrepreneur from './components/Entrepreneur';
import { Login, Logout } from './components/CypressLogin';
import Realtor from './components/Realtor';

import './App.css';

const { QLIK_DOMAIN, QLIK_PROXY_PREFIX, QLIK_APP_ID } = getConfig();

const SESSION_RENEWAL_INTERVAL = 1000 * 60 * 15; // 15 minutes
const SESSION_CHECK_INTERVAL = 1000; // 1 second
const SESSION_MAX_LIFE = 1000 * 60 * 30; // 30 minutes

// type ActiveSession = {
//   logoutUri?: string;
//   userDirectory?: string;
//   userId?: string;
//   userName?: string;
// };
//
// type InactiveSession = {
//   session?: string;
// };

// type SessionResponse = ActiveSession & InactiveSession;

// const sessionOpen = async (token: string) => {
//   const sessionRes = await axios
//     .get<SessionResponse>(`https://${QLIK_DOMAIN}/auth0/qps/user`, {
//       withCredentials: true,
//       headers: {
//         Authorization: `Bearer ${token}`,
//       },
//     })
//     .catch(err => console.error(err));

//   if (sessionRes) {
//     // session active
//     if (sessionRes?.data?.userId) {
//       return true;
//     }
//   }
//   return false;
// };

const renewSession = async (token: string) => {
  try {
    console.log('Renewing session');
    console.log(`https://${QLIK_DOMAIN}/${QLIK_PROXY_PREFIX}/hub/`);
    // renew session
    const jwtAuthResponse = await fetch(
      `https://${QLIK_DOMAIN}/${QLIK_PROXY_PREFIX}/hub/`,
      {
        method: 'GET',
        credentials: 'include',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    ).catch((err: any) => {
      console.warn('Authorization failed: ', err);
    });

    // check the response
    if (jwtAuthResponse && jwtAuthResponse.status === 200) {
      return true;
    }
    console.warn('Authorization failed: ', jwtAuthResponse);
    return false;
  } catch (error) {
    console.warn('Authorization failed: ', error);
    return false;
  }
};

const App = () => {
  const { getIdTokenClaims, isAuthenticated, loading } = useAuth0();

  const [claims, setClaims] = useState<any>(undefined);
  const [app, setApp] = useState<any>(undefined);
  const [sessionRenewInterval, setSessionRenewInterval] = useState<
    number | undefined
  >(undefined);
  const [sessionCheckInterval, setSessionCheckInterval] = useState<
    number | undefined
  >(undefined);
  const [lastSessionRenewal, setLastSessionRenewal] = useState<
    Date | undefined
  >(undefined);

  // trigger session renewal for the first time
  useEffect(() => {
    (async () => {
      const token = claims?.__raw;

      if (!token) {
        return;
      }

      if (!sessionRenewInterval) {
        const renewd = await renewSession(token);

        if (!renewd) {
          setSessionRenewInterval(undefined);
          setApp(undefined);
          return;
        }

        if (renewd) {
          setLastSessionRenewal(new Date());
        }

        const interval = setInterval(() => {
          renewSession(token);
          setLastSessionRenewal(new Date());
        }, SESSION_RENEWAL_INTERVAL);
        setSessionRenewInterval(interval);
      }
    })();
  }, [claims, sessionRenewInterval]);

  useEffect(() => {
    if (!sessionCheckInterval && lastSessionRenewal) {
      const interval = setInterval(() => {
        if (
          moment(lastSessionRenewal).add(SESSION_MAX_LIFE, 'milliseconds') <
          moment()
        ) {
          window.location.reload();
        }
      }, SESSION_CHECK_INTERVAL);
      setSessionCheckInterval(interval);
    }
  }, [sessionCheckInterval, lastSessionRenewal]);

  // set the claims
  useEffect(() => {
    (async () => {
      if (!loading && !isAuthenticated) {
        return;
      }

      const claimsRes = await getIdTokenClaims();
      if (!claims) {
        setClaims(claimsRes);
      }
    })();
  }, [claims, isAuthenticated, loading, getIdTokenClaims]);

  // load the app
  useEffect(() => {
    if (!sessionRenewInterval) {
      return;
    }
    (async () => {
      if (!app) {
        const config = {
          ...defaultQlikConfig,
          host: QLIK_DOMAIN,
          prefix: `/${QLIK_PROXY_PREFIX}/`,
        };
        const appRes = await QlikApp(QLIK_APP_ID, config).catch(err => {
          console.error('Qlik App error: ', err);
        });
        setApp(appRes);
      }
    })();
  }, [app, sessionRenewInterval, claims]);

  return (
    <div className="App">
      <Router history={history}>
        <Switch>
          <PrivateRoute
            component={() => <Redirect claims={claims} />}
            path="/"
            exact
          />
          <PrivateRoute
            path="/entrepreneur"
            component={() => <Entrepreneur app={app} claims={claims} />}
            exact
          />
          <PrivateRoute
            path="/realtor"
            component={() => <Realtor app={app} claims={claims} />}
            exact
          />
          <PrivateRoute
            path="/docs"
            component={() => <Documentation app={app} />}
            exact
          />
          <Route path="/cypress-login" component={() => <Login />} exact />
          <Route path="/cypress-logout" component={() => <Logout />} exact />
        </Switch>
      </Router>
    </div>
  );
};

export default App;
