import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import axios from 'axios';

import AppHead, { AgencyProps } from '../AppHead';
import AppBody from '../AppBody';
import Profit, { Props as ProfitProps } from '../Profit';
import {
  MarketShareMobile,
  MarketShareDesktop,
  Props as MarketShareProps,
} from './MarketShare';
import AreaFilter from './AreaFilter';
import Feedback, { Props as FeedbackProps } from '../FeedBack';
import { MeterBlockHeading, MeterBlockHeadingWithTag } from '../MeterBlock';
import { BlockLoader } from '../Loader';
import { MEASURES, DIMENSIONS, FIELDS } from '../../config/qlik-config';
import { MeterProp } from '../../types';
import {
  profitMeasures,
  feedbackMeasures,
  staffMeasures,
  marketShareMeasures,
} from '../../config/qlik-config.measures';
import {
  MONEY_FORMAT,
  qlikHyperCube,
  qlikHyperCubeWithDimensions,
} from '../../utils/qlik-utils';
import Stores from './Stores';
import { Props as StoreProps } from './Store';
import {
  StaffDesktop,
  StaffMobile,
  StaffMember as StaffMemberProps,
} from './Staff';
import {
  usePersistedCalendarState,
  usePersistedState,
} from '../../utils/persisted-state';
import { getMarketAreaDisplayPeriod } from './utils';

const METADATA_HANDLER_URL = `https://${window.location.host}/api/metadata`;

const Wrapper = styled.div`
  @media (min-width: ${props => props.theme.break.min}) {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
  }
`;

const Desktop = styled.div`
  @media (max-width: ${props => props.theme.break.max}) {
    display: none;
  }
`;
const Mobile = styled.div`
  @media (min-width: ${props => props.theme.break.min}) {
    display: none;
  }
`;

const DesktopLeft = styled.div`
  @media (min-width: ${props => props.theme.break.min}) {
    width: 21rem;
  }
`;

const DesktopRight = styled.div`
  @media (min-width: ${props => props.theme.break.min}) {
    flex-grow: 1;
    margin-left: 1rem;
  }
`;

type DefaultZipCodesResponse = {
  default_zip_codes: string[];
};

const EntrepreneurView = ({ app, claims }: { app: any; claims: any }) => {
  // area selector states
  const [defaultZipCodes, setDefaultZipCodes] = useState<any>([]);

  const [selectedAgency, setAgency] = useState<string>('');

  // session persisted
  const [selectedZipCodes, setSelectedZipCodes] = usePersistedState<string[]>(
    'selected-zip-codes',
    [],
  );
  const [selectedStore, setSelectedStore] = usePersistedState<
    string | undefined
  >('selected-store', undefined);
  const [dateRange, setDateRange] = usePersistedCalendarState('daterange');

  // other
  const [hideStaff, setHideStaff] = useState<boolean>(true);

  // cube states
  const [profitCube, setProfitCube] = useState<ProfitProps | undefined>(
    undefined,
  );
  const [marketShareCube, setMarketShareCube] = useState<
    MarketShareProps | undefined
  >(undefined);
  const [staffCube, setStaffCube] = useState<StaffMemberProps[] | undefined>(
    undefined,
  );
  const [currentStaffCount, setCurrentStaffCountCube] = useState<
    { staffCount: MeterProp } | undefined
  >(undefined);
  const [agencyCube, setAgencyCube] = useState<AgencyProps[] | undefined>(
    undefined,
  );
  const [storeCube, setStoreCube] = useState<StoreProps[] | undefined>(
    undefined,
  );
  const [feedbackCube, setFeedbackCube] = useState<FeedbackProps | undefined>(
    undefined,
  );

  const saveDefaultZipCodes = async (zipCodes: any[]) => {
    const default_zip_codes = zipCodes.map((z: any) => z.zip);
    return await axios
      .post<any>(
        METADATA_HANDLER_URL,
        {
          default_zip_codes,
        },
        {
          withCredentials: true,
          headers: {
            Authorization: `Bearer ${claims?.__raw}`,
          },
        },
      )
      .catch(err => console.error(err));
  };

  useEffect(() => {
    const CancelToken = axios.CancelToken; // https://codesandbox.io/s/l458746w89?from-embed
    const source = CancelToken.source();
    (async () => {
      if (claims?.__raw) {
        const res = await axios
          .get<DefaultZipCodesResponse>(METADATA_HANDLER_URL, {
            withCredentials: true,
            headers: {
              Authorization: `Bearer ${claims?.__raw}`,
            },
            cancelToken: source.token,
          })
          .catch(err => {
            if (axios.isCancel(err)) {
              // console.log('cancelled');
            } else {
              console.error(err);
            }
          });
        if (res && res.data) {
          setDefaultZipCodes(res.data.default_zip_codes);
        }
      }
    })();
    return () => {
      source.cancel();
    };
  }, [claims]);

  useEffect(() => {
    if (defaultZipCodes.length && !selectedZipCodes.length) {
      setSelectedZipCodes(defaultZipCodes);
    }
  }, [defaultZipCodes, selectedZipCodes, setSelectedZipCodes]);

  // Set cubes on load.
  useEffect(() => {
    if (!app) {
      return;
    }

    qlikHyperCube(
      app,
      [[MEASURES['Palkkiot (Yrittäjä)'], 'rewards'], ...profitMeasures],
      MONEY_FORMAT,
      setProfitCube,
    );
    qlikHyperCube(app, marketShareMeasures, {}, setMarketShareCube);
    qlikHyperCube(app, feedbackMeasures, {}, setFeedbackCube);

    qlikHyperCubeWithDimensions(
      app,
      [[DIMENSIONS['Yritys'], 'agency']],
      [[MEASURES['Yritys lkm'], 'numCompanies']],
      {},
      setAgencyCube,
    );

    qlikHyperCubeWithDimensions(
      app,
      [
        [DIMENSIONS['Markkinointi Nimi'], 'marketingName'],
        [DIMENSIONS['Yritys'], 'agency'],
      ],
      [[MEASURES['Palkkiot Kaikki Myymalat'], 'rewards']],
      {},
      setStoreCube,
      { qSuppressZero: false, qSuppressMissing: true },
    );

    qlikHyperCube(
      app,
      [[MEASURES['Henkilömäärä'], 'staffCount']],
      {},
      setCurrentStaffCountCube,
    );

    qlikHyperCubeWithDimensions(
      app,
      [
        [DIMENSIONS['YrityksenVälittäjä'], 'realtor'],
        [DIMENSIONS['Yritys'], 'agency'],
      ],
      staffMeasures,
      {},
      setStaffCube,
    );
  }, [app]);

  useEffect(() => {
    if (!app) {
      return;
    }

    app.field(FIELDS.MarkkinointiNimi, '$').clear();

    if (selectedStore) {
      app
        .field(FIELDS.MarkkinointiNimi, '$')
        .selectValues([selectedStore], true, true);
    }
  }, [selectedStore, app]);

  useEffect(() => {
    if (app) {
      app.field(FIELDS.Pvm, '$').clear();
      const { startDate, endDate } = dateRange;
      const rangeStart = moment(startDate).format('D.M.YYYY');
      const rangeEnd = moment(endDate).format('D.M.YYYY');
      app
        .field(FIELDS.Pvm, '$')
        .selectMatch(`>=${rangeStart}<=${rangeEnd}`, true);
    }
  }, [dateRange, app]);

  useEffect(() => {
    if (app) {
      app.field(FIELDS.Postinumeroalue, 'MO').clear();
      // str zips to integers
      const args = selectedZipCodes.map((x: string) => parseInt(x, 10));
      app.field(FIELDS.Postinumeroalue, 'MO').selectValues(args, true, true);
    }
  }, [app, selectedZipCodes]);

  useEffect(() => {
    if (app) {
      app.field(FIELDS.Yritys, 'MO').clear();
      // str zips to integers
      if (selectedAgency) {
        app.field(FIELDS.Yritys, 'MO').selectMatch(selectedAgency, true);
      }
    }
  }, [app, selectedAgency]);

  const marketAreaDisplayPeriod = getMarketAreaDisplayPeriod(
    dateRange.startDate,
    dateRange.endDate,
  );

  const isMultiAgency = (agencyCube?.length || 0) > 1;

  const onSelectAgency = (value: string) => {
    setAgency(value);
  };

  // some data apparently comes from some misc agencies, so eg. staff needs to be filtered to match user's agencies
  const userAgencies = agencyCube?.map(a => a.agency.text);

  const agency = isMultiAgency
    ? selectedAgency
    : (userAgencies && userAgencies[0]) || '';

  // store name or agency name to display in app head
  const currentAgency = isMultiAgency ? agency : undefined;
  const name = isMultiAgency ? undefined : selectedStore || agency;

  // Filter out people who are not of this agency.
  const filteredStaff = (
    staffCube || []
  ).filter(({ agency: { text } }: StaffMemberProps) =>
    selectedAgency ? text === selectedAgency : userAgencies?.includes(text),
  );

  return (
    <>
      <AppHead
        {...{
          dateRange,
          setDateRange,
          currentAgency,
          name,
          selectedStore,
          setSelectedStore,
          onSelectAgency,
          claims,
          agencies: isMultiAgency ? userAgencies : null,
        }}
        showStores
      />
      <AppBody>
        {storeCube && (
          <Stores
            stores={storeCube}
            selectedStore={selectedStore}
            setSelectedStore={setSelectedStore}
          />
        )}
        <Wrapper>
          <DesktopLeft>
            {!profitCube && <BlockLoader height={'37rem'} />}
            {profitCube && (
              <Profit {...profitCube} heading="Myymälän tulos ja toiminta" />
            )}
            {!feedbackCube && <BlockLoader height={'10.5rem'} />}
            {feedbackCube && <Feedback {...feedbackCube} />}
          </DesktopLeft>
          <DesktopRight>
            <Mobile>
              {!marketShareCube && <BlockLoader height={'30rem'} />}
              {marketShareCube && (
                <>
                  <MeterBlockHeading>
                    Markkinaosuus ({marketAreaDisplayPeriod[0]} -{' '}
                    {marketAreaDisplayPeriod[1]})
                  </MeterBlockHeading>
                  <AreaFilter
                    {...{
                      saveDefaultZipCodes,
                      setSelectedZipCodes,
                      setDefaultZipCodes,
                      defaultZipCodes,
                      selectedZipCodes,
                    }}
                  />
                  <MarketShareMobile {...marketShareCube} />
                </>
              )}
              {!filteredStaff.length && <BlockLoader height={'30rem'} />}
              {!!filteredStaff.length && currentStaffCount && (
                <>
                  <MeterBlockHeadingWithTag
                    tag={currentStaffCount.staffCount.num}
                    action={setHideStaff}
                    hideAll={hideStaff}
                    itemCount={filteredStaff.length}
                  >
                    Henkilökunta
                  </MeterBlockHeadingWithTag>
                  <StaffMobile
                    staff={filteredStaff}
                    hideStaff={hideStaff}
                    staffCount={currentStaffCount.staffCount.num}
                  />
                </>
              )}
            </Mobile>
            <Desktop>
              {!filteredStaff.length && <BlockLoader height={'26rem'} />}
              {!!filteredStaff.length && currentStaffCount && (
                <StaffDesktop
                  staff={filteredStaff}
                  hideStaff={hideStaff}
                  setHideStaff={setHideStaff}
                  staffCount={currentStaffCount.staffCount.num}
                />
              )}
              {!marketShareCube && <BlockLoader height={'26rem'} />}
              {marketShareCube && (
                <MarketShareDesktop
                  {...{
                    marketAreaDisplayPeriod,
                    saveDefaultZipCodes,
                    setSelectedZipCodes,
                    setDefaultZipCodes,
                    defaultZipCodes,
                    selectedZipCodes,
                    ...marketShareCube,
                  }}
                />
              )}
            </Desktop>
          </DesktopRight>
        </Wrapper>
      </AppBody>
    </>
  );
};

export default EntrepreneurView;
