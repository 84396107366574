import Auth0Lock from 'auth0-lock';
import getConfig from '../config';

const REDIRECT_URL = 'https://kima-dashboard.kiinteistomaailma.fi:3000';

const current = {
  appState: { targetUrl: '/' },
  scope: 'openid profile email',
  audience: 'default',
  redirect_uri: REDIRECT_URL,
};

const { AUTH0_CLIENT_ID, AUTH0_DOMAIN } = getConfig();

// only create once, in case rerenders
let lock: Auth0LockStatic | undefined = undefined;

const getLock = () =>
  new Auth0Lock(AUTH0_CLIENT_ID, AUTH0_DOMAIN, {
    auth: {
      redirectUrl: REDIRECT_URL,
      params: { state: 'cypress' },
    },
    allowedConnections: ['Username-Password-Authentication'], // so that the Azure AD login won't take over
  });

export const Login = (): null => {
  // @ts-ignore
  if (!window.Cypress) {
    throw Error('Only for Cypress');
  }
  if (!lock) {
    lock = getLock();
  }
  const state = encodeURIComponent(JSON.stringify(current));
  document.cookie = `a0.spajs.txs.cypress=${state}`;
  lock.show();
  return null;
};

export const Logout = (): null => {
  // @ts-ignore
  if (!window.Cypress) {
    throw Error('Only for Cypress');
  }
  if (!lock) {
    lock = getLock();
  }
  lock.logout({
    returnTo: REDIRECT_URL,
  });
  return null;
};

export default Login;
