export const config = {
  host: 'analytiikka.kiinteistomaailma.fi',
  prefix: '/auth0/',
  port: 443,
  isSecure: true,
};

export const MEASURES = {
  'MO vanhat KM, kpl': '1f0afb4d-a80c-4853-a7c1-6d00ccf84db4',
  'Yleisesittelyt toimeksiantoihin': '2774793f-a98b-4091-90a2-f985538eef8f',
  'MO KETJU yht, %-osuus': '330b5950-9597-4546-b0c6-0ee9b0b634f4',
  'MO yht KM, kpl': '363be295-06e5-48f3-ac3c-e7713a0fe6df',
  'Tarjoukset kpl': 'f947a3c9-52e4-4de9-8f9a-95b3b6e113e9',
  'MO vanhat, kpl': 'fef1e70d-00f9-448d-accb-b0b0b3972b24',
  'Avoimen toimeksiannon palkkio': 'kJPPJ',
  'Yksittäisesittelyt toimeksiantoihin': '90416edd-1d1d-414e-b67e-5398dc91cce6',
  'Tarjoukset kpl edv': 'HZUxX',
  'MO yht, kpl': '7ec5561c-c94b-4e60-a306-35f4637f2554',
  'MO uudet KM, kpl': 'c6f57d02-ab34-477d-8c00-ece6a5f07b79',
  'Tarjoukset toimeksiantoihin kpl': '4b6c95df-9e89-4224-84da-4373ce324e39',
  'MO uudet, %-osuus': '29afb1f0-b6a8-4779-a4d5-2da1e23f5451',
  'Myynti kpl edv': '599e116f-2bbd-42c0-9375-1193dd6a3332',
  'MO yht, muutos kpl': '6136e962-de50-4a87-b0ec-d09b294b4445',
  'MO yht, %-osuus': '7a8e846a-9480-409b-90ba-b93363b4e471',
  'MO vanhat, %-osuus': '7ecfd25f-b032-4783-8f0e-1cb695a4a128',
  'MO yht, muutos %-osuus': '95347240-5942-4898-9d73-b14ae714d1c8',
  Palkkiot: 'pCbDP',
  Kotikäynnit: 'LvPAmU',
  Puhelut: 'pbmJa',
  Kotihaut: 'LNmRpc',
  'MO uudet, muutos kpl': 'a79166e6-cfc9-4ebb-9bc3-26b2a8706b9e',
  'MO uudet, kpl': 'f3710724-6f95-4028-9567-93e2b3893304',
  'Asiakkaita näytöllä toimeksiantoihin (Välittäjä)':
    'd7880bef-98bb-44b1-a1bb-d83a9eae9404',
  'Palkkiot (Yrittäjä)': 'bd978c73-97a4-49f7-80b8-cad9279595cb',
  Asiakastyytyväisyys: '01d6d74a-6746-404d-a08e-622478081af1',
  'Tarjoukset toimeksiantoihin kpl (Välittäjä)':
    '1792c654-4565-4d2b-b057-3f5b66781439',
  'Myynti kpl': 'ETEvMAm',
  Yksittäisesittelyt: 'FjHjJD',
  'Ei aktiiviset toimeksiannot': 'VQjrg',
  'Yksittäisesittelyt toimeksiantoihin (Välittäjä)':
    'db40667d-be01-47b3-99c9-b5e7a2110e52',
  'Yleisesittelyt edv': 'e86480d2-c319-42b4-a89f-3326b0a4fd23',
  'Kotihaut edv': 'f6f3118a-0e53-4e7b-8964-286c25cab3ea',
  'Yksittäisesittelyt edv': '0b95a9d4-67d8-45ca-b221-8dfe7d995733',
  'Palkkiot edv': '2d1cf572-3af8-4c07-8021-0b9e63096361',
  'Asiakastyytyväisyys edv': '7279c100-34e8-4f36-9e9a-fc39fa8475dc',
  'Yleisesittelyt toimeksiantoihin (Välittäjä)':
    '46a58105-425e-4b44-87c5-67f1fcb0c873',
  'MO KETJU uudet, %-osuus': '7afb14c5-52de-44f2-ba8b-1bb07cf0eaee',
  'MO KETJU vanhat, %-osuus': '88172ddb-53c0-44b2-b27f-d0d012cbc905',
  Yleisesittelyt: 'DpEPfD',
  'Toimeksiannon Odotettu Palkkio': '6ba43b54-9c02-4184-8a7c-a4db66ad1211',
  Yhteydenottopyynnöt: 'a446f26c-5711-4627-880f-234f06e782c9',
  'Yleisesittelyt Henkilökunta': 'SBFDum',
  'Yleisesittelyt toimeksiantoihin (Välittäjä) (Myyty)':
    '0b428d6b-d63b-4b84-acea-4036cb562699',
  'Asiakkaita näytöllä toimeksiantoihin (Välittäjä) (Myyty)':
    '2c59208a-1c2e-4e37-901f-7e05224d2e84',
  'Tarjoukset toimeksiantoihin kpl (Välittäjä) (Myyty)':
    '31b244c8-5753-4d21-a616-c35580130af8',
  'Asiakkaita näytöllä toimeksiantoihin (Myyty)':
    '7d87b546-7280-4925-824a-dd3dab38df84',
  'Tarjoukset toimeksiantoihin kpl (Ei Myyty)': 'JgmBnS',
  'Asiakkaita näytöllä toimeksiantoihin (Ei myyty)': 'PuSnz',
  'Toimeksiannon Katselut portaaleissa (Myyty)': 'aQPYK',
  'Yksittäisesittelyt toimeksiantoihin (Välittäjä) (Myyty)':
    'b0877f84-753b-4c8a-a69a-483645cb3eb3',
  'Yleisesittelyt toimeksiantoihin (Ei myyty)':
    'bf94f4f4-2fa5-4870-9bd5-eb8b59bcf791',
  'Kotikäynnit edv': '54a9bc91-4566-4bcd-84f5-573ff3aa933e',
  'Yksittäisesittelyt toimeksiantoihin (Myyty)':
    '31f2effa-bca6-437f-bf92-a2402c335ffd',
  'Yksittäisesittelyt toimeksiantoihin (Ei myyty)': 'GHmjLE',
  'Toimeksiannon Odotettu Palkkio (Myyty)': 'cuNM',
  'Hankinnat edv': '322dbaf3-da80-48de-8cac-af9a630a5b91',
  'Puhelut edv': '52f8a6ce-d2da-4370-9b17-b64c0a7ea078',
  'Asiakkaita näytöllä toimeksiantoihin':
    'b8e9bc42-816b-4c0d-ab06-39ecd5441dd4',
  'Toimeksiannon päättymis PVM': 'e4731567-fd93-413d-9354-1d8633393ec7',
  'Yksityisesittelyt Henkilökunta': 'mmteWSj',
  'Asiakkaita näytöllä Henkilökunta': 'smzmZC',
  'Lähetetyt asiakasohjaukset': '22ba4a0b-a65a-49cc-a39a-9406f77c6eb9',
  'Lähetetyt ja hyväksytyt asiakasohjaukset edv':
    '6e0ae82f-81a0-497a-8803-49401e87b07c',
  'Yritys lkm': 'GAVQTg',
  Hankinnat: 'ZfPtgs',
  'Lähetetyt ja hyväksytyt asiakasohjaukset':
    'a68c2363-281f-44aa-aacd-c109e2a5e8ad',
  'Toimeksiannon Katselut portaaleissa': 'b38325c0-75fe-4d87-8f74-c4a75eef40e7',
  'Lähetetyt asiakasohjaukset edv': 'cff2b268-f3b3-4ef3-8ce1-6c57979b172b',
  'MO vanhat, muutos kpl': 'd7cd35e3-6bb3-4685-8d9f-b8ef98305e66',
  'MO vanhat, muutos %-osuus': 'cb42608d-d469-490d-889f-1712a855821e',
  'Palkkiot Kaikki Myymalat': 'e186c020-0098-4f1d-86af-7ccc87e30e37',
  'MO uudet, muutos %-osuus': 'f8d0df35-41d6-48a8-988c-f3614f4d40ce',
  'Yleisesittelyt toimeksiantoihin (Myyty)':
    '7840c038-4c65-486d-9c3b-5904adab9940',
  'Tarjoukset toimeksiantoihin kpl (Myyty)':
    '17d29395-db67-4446-8894-ba9b07e57de0',
  Henkilömäärä: '84bd78f3-6f81-4395-8419-f522008d6a44',
};

export const DIMENSIONS = {
  'Toimeksiannon osoite': '1c47107a-9cb1-465e-85c0-a06527fa01c0',
  'Toimeksiannon tila': '737cd351-5353-4967-8cd8-d1a84e1f06a7',
  Postitoimipaikka: 'qVrwnmm',
  'Markkinointi Nimi': 'TJcxR',
  'Välittäjä koodi': 'YbPScT',
  Pvm: '402dbd9c-5ae2-41f0-8484-a2ac49d039e8',
  'Ei aktiivisen toimeksiannon palkkio': 'fuZzLEh',
  Vuosi: 'PsCxzP',
  Kuukausi: 'd16b59ee-0451-4b94-9d09-7565d5b4fd84',
  'Kotikäynnin Asiakas': 'JndDRE',
  Alue: 'ee1839a4-f2e9-4ba1-874f-567c13954c38',
  'Ei aktiiviset kohdenumero': 'sUMTqaN',
  'Toimeksiannon kohdenumero': 'kxarLHV',
  ToimeksiantosopimuksenPäättymispäivä: 'BDdm',
  MyyntiPvm: 'pPsmtz',
  'Katselut portaaleissa': '4bd949a2-7e6f-4c8d-9900-c372ceb34347',
  'Toimeksiannon Odotettu palkkio': 'DeCyVtj',
  'Kotikäynnin Status': 'erdAqgy',
  Kauppatapa: 'rWukUmR',
  Yritys: 'a71e06e5-8cf4-4c25-b5bf-cf17c8d1baac',
  'Kotikäynnin Osoite': '92a88a69-af14-4567-aff4-55dff42e281f',
  Välittäjä: 'd78dd9eb-8c15-4582-b1b0-18caaee9b4b2',
  YrityksenVälittäjä: 'hyHDwr',
  Myymälä: 'pYRWhA',
};

export const FIELDS = {
  Pvm: 'Pvm',
  Postinumeroalue: 'Postinumeroalue',
  MarkkinointiNimi: 'MarkkinointiNimi',
  Yritys: 'Yritys',
};
