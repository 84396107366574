import React from 'react';
import styled from 'styled-components';
import { ReactComponent as PeopleIcon } from '../assets/people.svg';
import { TextBold } from '../config/default-styles';

const PeopleWrapper = styled.span`
  margin-left: 1rem;
  padding: 0 0.25rem 0 0.25rem;
  border-radius: 0.25rem;
  border: 1px solid ${props => props.theme.colors.border.primary};
`;

type Props = {
  count: string | number;
  className?: string;
};

export default ({ count, ...rest }: Props): JSX.Element => (
  <PeopleWrapper {...rest}>
    <PeopleIcon /> <TextBold>{count}</TextBold>
  </PeopleWrapper>
);
