import React from 'react';

import MeterBlock from '../../MeterBlock';
import { MeterPrimaryHomeVisit } from '../../MeterPrimary';
import { Props } from './index';

const HomeVisitsMobile = (props: Props): JSX.Element => {
  const { homeVisits, hideHomeVisits } = props;
  const objs = hideHomeVisits ? homeVisits.slice(0, 3) : homeVisits;

  return (
    <>
      {objs.map((h, index) => (
        <MeterBlock key={index}>
          <MeterPrimaryHomeVisit
            name={h.customer.text}
            address={h.address.text}
            status={h.status.text}
            date={h.date.text}
          />
        </MeterBlock>
      ))}
    </>
  );
};

export default HomeVisitsMobile;
