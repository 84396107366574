import React from 'react';
import styled from 'styled-components';
import StoreMobile, { StoreDesktop, Props as StoreProps } from './Store';
import { Wrapper as DefaultMeterBlock } from '../MeterBlock';
import { TextBold as TextBoldDefaut } from '../../config/default-styles';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  @media (min-width: ${props => props.theme.break.min}) {
    flex-direction: row;
  }
`;

const MobileWrapper = styled(DefaultMeterBlock)`
  @media (min-width: ${props => props.theme.break.min}) {
    display: none;
  }
`;

const DesktopWrapper = styled.div`
  align-items: baseline;
  display: flex;
  margin-top: -4rem;
  flex-direction: row;
  overflow-x: auto;
  @media (max-width: ${props => props.theme.break.max}) {
    display: none;
  }
`;

const AllStores = styled.div`
  color: ${props => props.theme.colors.text.white};
  margin-right: 3rem;
  margin-left: 1rem;
  cursor: pointer;
  &:hover {
    border-bottom: 2px solid ${props => props.theme.colors.border.ascent};
  }
  &.selected {
    border-bottom: 2px solid ${props => props.theme.colors.border.ascent};
  }
`;

const TextBold = styled(TextBoldDefaut)`
  margin: 0;
  padding: 0;
`;

export type Props = {
  stores: StoreProps[];
  selectedStore: string | undefined;
  setSelectedStore: Function;
};

const Stores = ({ stores, ...props }: Props): JSX.Element | null => {
  if (!stores || stores.length <= 1) {
    return null;
  }
  const { selectedStore, setSelectedStore } = props;

  return (
    <Wrapper>
      {/* Mobile */}
      {!selectedStore &&
        stores.map(s => (
          <MobileWrapper key={s.marketingName.text}>
            <StoreMobile
              marketingName={s.marketingName}
              rewards={s.rewards}
              setStore={setSelectedStore}
            />
          </MobileWrapper>
        ))}

      {/* Desktop */}
      <DesktopWrapper>
        {/* All stores selector */}
        <AllStores
          onClick={() => {
            setSelectedStore(undefined);
          }}
          className={!selectedStore ? 'selected' : 'unselected'}
        >
          <TextBold>Koko&nbsp;yritys</TextBold>
        </AllStores>

        {/* Map all stores */}
        {stores.map(s => (
          <div key={s.marketingName.text}>
            <StoreDesktop
              selected={
                s.marketingName.text === selectedStore
                  ? 'selected'
                  : 'unselected'
              }
              marketingName={s.marketingName}
              rewards={s.rewards}
              setStore={setSelectedStore}
            />
          </div>
        ))}
      </DesktopWrapper>
    </Wrapper>
  );
};

export default Stores;
