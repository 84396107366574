import React from 'react';
import styled from 'styled-components';
import { Tag } from '../MeterBlock';
import { H2, Text } from '../../config/default-styles';
import { ReactComponent as ArrowRight } from '../../assets/right-arrow.svg';
import { SortType, SortDir, SortingType, ValueType } from '../../types';

enum gridGapKey {
  row = 'row',
  col = 'col',
}

type GridGapType =
  | {
      [key in gridGapKey]: string;
    }
  | string;

interface GridProps {
  colCount: number;
  gridGap: GridGapType;
  gridTemplate: string;
}

interface WrapperProps {
  borderRadius: string;
}

type ThType = {
  text: string;
  type: string;
  sortable?: boolean;
};

export type PartialType = { [propName: string]: ValueType };

interface Props {
  header: ThType[];
  rows: RowType[];
  title: string;
  renderHeaderCell: Function;
  renderCell: Function;
  gridGap: GridGapType;
  gridTemplate: string;
  borderRadius: string;
  totalCount: number;
  onToggleDisplayMode: Function;
  showAll: boolean;
  className?: string;
  onSort?: Function;
  sorting: SortType;
  children?: React.ReactNode;
  noResults?: string;
}

interface ToggleProps {
  showAll: boolean;
}

interface HeaderButtonProps {
  sortingProps: SortType;
  children: React.ReactNode;
}

type CellType = {
  type: string;
  props: PartialType;
};

type RowType = CellType[];

export const GridCell = styled.div``;

const defaultRenderHeaderCell = (text: string) => <Text>{text}</Text>;

const HeaderCell = styled(GridCell)(
  ({ theme }) => `

  @media (min-width: ${theme.break.min}) {
    padding-bottom: .5em;
    line-height: 1.2;
    color: ${theme.colors.text.primary};
    display: flex;
  }
`,
);

const HeaderCellButton = styled(HeaderCell).attrs(
  ({ children }: HeaderButtonProps) => ({
    as: 'button',
    type: 'button',
    children: (
      <>
        {children}
        <ArrowRight />
      </>
    ),
  }),
)<HeaderButtonProps>(
  ({ sortingProps: { key, dir, sortBy } }) => `
  background: none;
  border: 0;
  cursor: pointer;
  text-align: inherit;
  padding: 0;
  margin: 0;
  appearance: none;
  align-items: center;

  ${
    key
      ? `
      &:after {
        content: '(${
          dir === SortDir.asc
            ? { alphabetical: `A-Z`, numeric: `0-9` }[sortBy]
            : { alphabetical: `Z-A`, numeric: `9-0` }[sortBy]
        })';
        margin-left: .4em;
        font-size: .7em;
        white-space: nowrap;
      }

      &:last-child {
        position: relative;

        // display sorting on top of row, because it pushes content if displayed on right side.
        &:after {
          position: absolute;
          top: -1.25rem;
        }
      }
    `
      : ''
  }

  &,
  &:focus {
    outline: 0;
  }

  svg {
    width: .4em;
    margin-left: .4em;
    fill: currentColor;
    transform: rotate(90deg);
    transition: opacity .125s ease-in;
  }

  &:disabled {
    cursor: default;

    &:after {
      display: none;
    }

    svg {
      opacity: .3;
      transition-timing-function: ease-out;
      transition-duration: .75s;
    }
  }
`,
);

const ToggleDisplayModeButton = styled.button.attrs({ type: 'button' })<
  ToggleProps
>(
  ({ theme, showAll }) => `
  display: flex;
  align-items: center;
  color: ${theme.colors.text.green};
  padding: .5em 0 .5em 1em;
  font-weight: 700;
  transition: opacity .125s ease-in;

  &, &:focus {
    outline: 0;
  }

  &:disabled {
    opacity: .3;
    cursor: default;
    transition-timing-function: ease-out;
    transition-duration: .75s;
  }

  svg {
    fill: currentColor;
    transition: transform .125s ease-in;
    margin-left: .5rem;

    ${
      !showAll
        ? 'transform: rotate(90deg);'
        : `
        transform: rotate(-90deg);
        transition-timing-function: ease-out;
      `
    };
  }
`,
);

const HeaderTitleWrap = styled.div`
  display: flex;
  align-items: center;

  ${Tag} {
    margin-left: 0.5rem;
  }
`;

const GridHeader = styled.div(
  ({ theme }) => `
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: .5rem 0;
  font-size: .875rem;

  ${ToggleDisplayModeButton} {
    margin-left: .5rem;
  }

  @media (min-width: ${theme.break.min}) {
    background: ${theme.colors.background.white};
    padding: .75rem 1.5rem;
    flex-wrap: nowrap;
  }
`,
);

const Cell = styled(GridCell)`
  @media (max-width: ${p => p.theme.break.max}) {
    padding: 0.5em 1em;

    &:first-child,
    &:last-child {
      background: ${p => p.theme.colors.background.white};
      padding-top: 1em;
      padding-bottom: 1em;
    }

    &:not(:first-child):not(:last-child):not(:nth-last-child(2)) {
      border-bottom: 1px solid ${p => p.theme.colors.border.primary};
    }

    &:not(:last-child) {
      margin-bottom: 0.25em;
    }
  }
`;

const GridRow = styled.div(
  ({ theme }) => `
  margin-bottom: 1.5em;
  background: #f9fafb;

  @media (min-width: ${theme.break.min}) {
    display: inherit;
    grid-template-columns: inherit;
    grid-column: 1 / -1;
    grid-column-gap: inherit;
    margin-bottom: 0;
    padding: .75rem 1.5rem;
    background: ${theme.colors.background.white};

    ${GridCell} {
      &:last-child {
        justify-content: flex-end;
        display: flex;
      }
    }
  }
`,
);

// Sticky table header row
const HeaderRow = styled(GridRow)`
  @media (min-width: ${p => p.theme.break.min}) {
    padding-bottom: 0;
    position: sticky;
    top: 0;
  }

  @media (max-width: ${p => p.theme.break.max}) {
    display: none;
  }
`;

const HeaderRowInner = styled(GridRow)(
  ({ theme }) => `
  border-bottom: 1px solid ${theme.colors.border.primary};
  padding: 0 !important; // this is as should be
  align-items: flex-end;
`,
);

const Row = styled(GridRow)`
  align-items: center;
`;

const getGridGap = (gap: GridGapType, key: gridGapKey): string =>
  typeof gap === 'string' ? gap : gap[key];

const Grid = styled.div<GridProps>(
  ({ theme, colCount, gridGap, gridTemplate }) => `
  @media (min-width: ${theme.break.min}) {
    display: grid;
    grid-template-columns: ${
      gridTemplate ? gridTemplate : `repeat(${colCount}, 1fr)`
    };

    ${
      gridGap
        ? `
      grid-row-gap: ${getGridGap(gridGap, gridGapKey.row)};
      grid-column-gap: ${getGridGap(gridGap, gridGapKey.col)};

      ${GridRow}:nth-of-type(2) {
        // Negate row gap after header
        margin-top: -${getGridGap(gridGap, gridGapKey.row)};
      }
    `
        : ''
    }
  }
`,
);

const Wrapper = styled.div<WrapperProps>(
  ({ borderRadius: bRad = '', theme }) => `
  ${
    bRad
      ? `
      @media (min-width: ${theme.break.min}) {
        ${Row} {
          border-radius: ${bRad};

          &:nth-of-type(2) {
            border-radius: 0 0 ${bRad} ${bRad};
          }
        }

        ${GridHeader} {
          border-radius: ${bRad} ${bRad} 0 0;
        }
      }

      @media (max-width: ${theme.break.max}) {
        ${GridCell} {
          &:first-child {
            border-radius: ${bRad} ${bRad} 0 0;
          }

          &:last-child {
            border-radius: 0 0 ${bRad} ${bRad};
          }
        }
      }
    `
      : ''
  };
`,
);

const NoResults = styled.p(
  ({ theme }) => `
  margin: 0.5em 0 1em;
  font-size: 0.875rem;

  @media (max-width: ${theme.break.max}) {
    padding: 0.5em 1em;
  }
`,
);

type GetThProps = {
  sortable?: boolean;
  type: string;
  sorting: SortType;
  onSort: Function;
  rowCount: number;
};

type ThPart = {
  Component: typeof HeaderCell;
  props: any;
};

/*
 * Returns props and Component.
 * Return values depend on if the column should be sortable and sorting itself should be possible based on given props.
 * Component is either a basic header cell or a button type of header cell.
 */
const getThParts = ({
  sortable,
  type,
  sorting,
  onSort,
  rowCount,
}: GetThProps): ThPart => {
  const isSortingProvided = sortable && typeof sorting === 'object';
  return isSortingProvided
    ? {
        props: {
          onClick: () => {
            onSort(type);
          },
          disabled: rowCount < 2,
          sortingProps:
            type === sorting.key
              ? sorting
              : {
                  key: '',
                  dir: SortDir.asc,
                  sortBy: SortingType.alphabetical,
                },
        },
        Component: HeaderCellButton,
      }
    : {
        props: {
          sortingProps: {
            key: '',
            dir: SortDir.asc,
            sortBy: SortingType.alphabetical,
          },
        },
        Component: HeaderCell,
      };
};

const TableGrid = ({
  header,
  rows,
  title,
  renderHeaderCell = defaultRenderHeaderCell,
  renderCell,
  gridGap = '',
  totalCount,
  gridTemplate,
  onToggleDisplayMode,
  showAll,
  onSort = () => {},
  sorting,
  children,
  noResults,
  ...rest
}: Props) => {
  return (
    <Wrapper {...rest}>
      <GridHeader>
        <HeaderTitleWrap>
          <H2>{title}</H2>
          <Tag>{totalCount}</Tag>
        </HeaderTitleWrap>
        {children}
        <ToggleDisplayModeButton
          onClick={() => {
            onToggleDisplayMode();
          }}
          showAll={showAll}
          disabled={showAll || totalCount > rows.length ? false : true}
        >
          {showAll ? 'Näytä vähemmän' : 'Näytä kaikki'}
          <ArrowRight />
        </ToggleDisplayModeButton>
      </GridHeader>
      <Grid
        colCount={header.length}
        gridGap={gridGap}
        gridTemplate={gridTemplate}
        className="table-grid"
      >
        <HeaderRow className="table-row table-row--header">
          <HeaderRowInner>
            {header.map(({ sortable, type, text }: ThType) => {
              const { props: thProps, Component: ThComponent } = getThParts({
                sortable,
                type,
                sorting,
                rowCount: rows.length,
                onSort,
              });
              return (
                <ThComponent
                  key={text.replace(/\s/, '-').toLowerCase()}
                  data-type={type}
                  {...thProps}
                >
                  {renderHeaderCell(text)}
                </ThComponent>
              );
            })}
          </HeaderRowInner>
        </HeaderRow>
        {rows.map((row: RowType, i: number) => (
          <Row className="table-row" key={`tr-${i}`}>
            {row.map(({ type, props }: CellType) => (
              <Cell data-type={type} key={`${type}-${i}`}>
                {renderCell(props, type)}
              </Cell>
            ))}
          </Row>
        ))}
        {!rows.length ? (
          <Row>
            <NoResults>{noResults || 'Ei tuloksia.'}</NoResults>
          </Row>
        ) : null}
      </Grid>
    </Wrapper>
  );
};

export default TableGrid;
