import { MEASURES } from './qlik-config';

const profitMeasures = [
  [MEASURES['Palkkiot edv'], 'rewardsLastYear'],
  [MEASURES['Myynti kpl'], 'salesPcs'],
  [MEASURES['Kotikäynnit'], 'homeVisits'],
  [MEASURES['Kotikäynnit edv'], 'homeVisitsLastYear'],
  [MEASURES['Hankinnat'], 'acquisitions'],
  [MEASURES['Hankinnat edv'], 'acquisitionLastYear'],
  [MEASURES['Puhelut'], 'calls'],
  [MEASURES['Puhelut edv'], 'callsLastYear'],
  [MEASURES['Kotihaut'], 'homeSearches'],
  [MEASURES['Kotihaut edv'], 'homeSearchesLastYear'],
  [MEASURES['Yksittäisesittelyt'], 'privateDisplays'],
  [MEASURES['Yksittäisesittelyt edv'], 'privateDisplaysLastYear'],
  [MEASURES['Yleisesittelyt'], 'publicDisplays'],
  [MEASURES['Yleisesittelyt edv'], 'publicDisplaysLastYear'],
  [MEASURES['Lähetetyt asiakasohjaukset'], 'customerDirectionsPcs'],
  [MEASURES['Tarjoukset kpl edv'], 'offersLastYearPcs'],
  [MEASURES['Tarjoukset kpl'], 'offersPcs'],
  [MEASURES['Lähetetyt asiakasohjaukset edv'], 'customerDirectionsPcsLastYear'],
];

const marketShareMeasures = [
  [MEASURES['MO KETJU yht, %-osuus'], 'chainTotalPercent'],
  [MEASURES['MO KETJU uudet, %-osuus'], 'chainNewPercent'],
  [MEASURES['MO KETJU vanhat, %-osuus'], 'chainOldPercnt'],
  [MEASURES['MO vanhat, kpl'], 'oldPcs'],
  [MEASURES['MO vanhat KM, kpl'], 'oldPcsKm'],
  [MEASURES['MO yht KM, kpl'], 'totalPcsKm'],
  [MEASURES['MO yht, kpl'], 'totalPcs'],
  [MEASURES['MO uudet KM, kpl'], 'newPcsKm'],
  [MEASURES['MO uudet, kpl'], 'newPcs'],
  [MEASURES['MO uudet, %-osuus'], 'newPercent'],
  [MEASURES['MO yht, muutos kpl'], 'totalChangePcs'],
  [MEASURES['MO yht, %-osuus'], 'totalPercent'],
  [MEASURES['MO vanhat, %-osuus'], 'oldPercent'],
  [MEASURES['MO yht, muutos %-osuus'], 'totalChangePercent'],
  [MEASURES['MO vanhat, muutos %-osuus'], 'oldChangePercent'],
  [MEASURES['MO vanhat, muutos kpl'], 'oldChangePcs'],
  [MEASURES['MO uudet, muutos %-osuus'], 'newChangePercent'],
  [MEASURES['MO uudet, muutos kpl'], 'newChangePcs'],
];

const staffMeasures = [
  [MEASURES['Palkkiot'], 'rewards'],
  [MEASURES['Palkkiot edv'], 'rewardsLastYear'],
  [MEASURES['Myynti kpl'], 'salesPcs'],
  [MEASURES['Kotikäynnit'], 'homeVisits'],
  [MEASURES['Kotikäynnit edv'], 'homeVisitsLastYear'],
  [MEASURES['Hankinnat'], 'acquisitions'],
  [MEASURES['Hankinnat edv'], 'acquisitionLastYear'],
  [MEASURES['Puhelut'], 'calls'],
  [MEASURES['Puhelut edv'], 'callsLastYear'],
  [MEASURES['Yksittäisesittelyt'], 'privateDisplays'],
  [MEASURES['Yksittäisesittelyt edv'], 'privateDisplaysLastYear'],
  [MEASURES['Yleisesittelyt'], 'publicDisplays'],
  [MEASURES['Yleisesittelyt edv'], 'publicDisplaysLastYear'],
  [MEASURES['Asiakkaita näytöllä toimeksiantoihin'], 'peopleInPublicDisplays'],
  [MEASURES['Yksityisesittelyt Henkilökunta'], 'privateDisplaysStaff'],
  [MEASURES['Yleisesittelyt Henkilökunta'], 'publicDisplaysStaff'],
  [MEASURES['Asiakkaita näytöllä Henkilökunta'], 'peopleInPublicDisplaysStaff'],
];

const feedbackMeasures = [
  [MEASURES['Asiakastyytyväisyys'], 'feedback'],
  [MEASURES['Asiakastyytyväisyys edv'], 'feedbackLastYear'],
];

export { profitMeasures, feedbackMeasures, staffMeasures, marketShareMeasures };
