import { Trend } from '../../Trends';
import { MeterProp } from '../../../types';

export type Props = {
  chainTotalPercent: MeterProp;
  chainNewPercent: MeterProp;
  chainOldPercnt: MeterProp;
  oldPcs: MeterProp;
  oldPcsKm: MeterProp;
  totalPcsKm: MeterProp;
  totalPcs: MeterProp;
  newPcsKm: MeterProp;
  newPcs: MeterProp;
  newPercent: MeterProp;
  totalChangePcs: MeterProp;
  totalPercent: MeterProp;
  oldPercent: MeterProp;
  totalChangePercent: MeterProp;
  oldChangePercent: MeterProp;
  oldChangePcs: MeterProp;
  newChangePercent: MeterProp;
  newChangePcs: MeterProp;
};

export const getTrend = (change: number) => {
  if (isNaN(change)) {
    return Trend.NoTrend;
  }
  if (change < 0) {
    return Trend.Negative;
  }
  if (change > 0) {
    return Trend.Positive;
  }
  return Trend.NoChange;
};

export const getChangePercentStr = (x: number, percision: number = 0) => {
  return isNaN(x) ? '-' : `${(x * 100).toFixed(percision).replace('.', ',')} %`;
};

export { default as MarketShareMobile } from './Mobile';
export { default as MarketShareDesktop } from './Desktop';
