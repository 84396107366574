import React from 'react';

import MeterBlock from './MeterBlock';
import { MeterHeading, MeterPrimary } from './MeterPrimary';
import { Trend, getTrend, getTrendPercent } from './Trends';
import { MeterProp } from '../types';

export type Props = {
  feedback: MeterProp;
  feedbackLastYear: MeterProp;
};

const Feedback = (props: Props): JSX.Element => {
  const {
    feedback: feedbackProp,
    feedbackLastYear: feedbackLastYearProp,
  } = props;

  let feedback,
    feedbackLastYear = undefined;
  try {
    feedback = feedbackProp.num.toFixed(2);
    feedbackLastYear = feedbackLastYearProp.num.toFixed(2);
  } catch {}

  const trendPercent =
    feedback && feedbackLastYear
      ? getTrendPercent(feedbackProp.num, feedbackLastYearProp.num)
      : undefined;
  const trend = trendPercent
    ? getTrend(feedbackProp.num, feedbackLastYearProp.num)
    : Trend.NoChange;

  return (
    <MeterBlock>
      <MeterHeading>Asiakaspalaute</MeterHeading>
      <MeterPrimary
        {...{
          currentPeriod: `${feedback ? feedback : '-'} / 5 `,
          legend: 'Asiakastyytyväisyys',
          trendPercent: `${trendPercent ? trendPercent : 'N/A'}`,
          trend,
        }}
      />
    </MeterBlock>
  );
};

export default Feedback;
