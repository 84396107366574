import React, { useState } from 'react';
import styled from 'styled-components';

import MarketAreaSelector from './MarketAreaSelector';
import Modal from '../Modal';
import { ReactComponent as Pin } from '../../assets/map-pin.svg';
import { ReactComponent as Arrow } from '../../assets/right-arrow.svg';
import { Text, TextBold } from '../../config/default-styles';
import { grouped, mapZipStr } from './utils';
import { Zip } from '../../types';

const style = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    border: 'none',
    height: '90%',
    minWidth: '90%',
  },
  overlay: {
    background: 'rgba(0, 0, 0, 0.5)',
    zIndex: 1000,
  },
};

const Wrapper = styled.div`
  background: #f5f6f7; // NOTE: not a theme color
  border: 1px solid ${p => p.theme.colors.background.tertiary};
  border-radius: 0.5rem;
  margin-bottom: 1rem;
  display: flex;
  cursor: pointer;

  @media (min-width: ${props => props.theme.break.min}) {
    border-radius: 1rem;
    padding-left: 1rem;
    height: 2rem;
    margin-left: 1.5rem;
    margin-bottom: 0;
    background-color: ${props => props.theme.colors.background.white};
    flex-direction: row;
    align-items: center;
  }
`;

const Box = styled.div`
  display: flex;
  height: 3rem;
  width: 3rem;
  border-radius: 0.5rem;
  margin: 0.5rem;
  background-color: #dce7e6; // NOTE: not a theme color
  align-items: center;
  justify-content: space-around;

  @media (min-width: ${props => props.theme.break.min}) {
    width: auto;
    height: auto;
    background-color: ${props => props.theme.colors.background.white};
  }
`;

const AreaWrapper = styled.div`
  margin: 0.5rem;
  color: ${props => props.theme.colors.text.ascent};
  @media (min-width: ${props => props.theme.break.min}) {
    margin-top: 0.4rem;
    margin-left: 0.5rem;
  }
`;
const AreaLabel = styled(TextBold)``;

const LightText = styled(Text)`
  color: ${props => props.theme.colors.text.secondary};
`;

const DefaultAreaMobile = styled(LightText)`
  margin-top: 0.25rem;
  display: block;
  @media (min-width: ${props => props.theme.break.min}) {
    display: none;
  }
`;

const DefaultAreaDesktop = styled.div`
  margin-left: 1rem;
  @media (max-width: ${props => props.theme.break.min}) {
    display: none;
  }
`;
const ArrowWrapper = styled.div`
  margin-left: auto;
  margin-right: 2rem;
  display: flex;
  align-items: center;
`;

type ModalProps = {
  isOpen: boolean;
  selectedZipCodes: Zip[];
  setIsOpen: Function;
  saveDefaultZipCodes: Function;
  setSelectedZipCodes: Function;
  setDefaultZipCodes: Function;
};

const MarketAreaSelectorModal = (props: ModalProps): JSX.Element => {
  const {
    setIsOpen,
    isOpen,
    saveDefaultZipCodes,
    selectedZipCodes,
    setSelectedZipCodes,
    setDefaultZipCodes,
  } = props;
  return (
    <Modal isOpen={isOpen} style={style}>
      <MarketAreaSelector
        setIsOpen={setIsOpen}
        setSelectedZipCodes={setSelectedZipCodes}
        saveDefaultZipCodes={saveDefaultZipCodes}
        selectedZipCodes={selectedZipCodes}
        setDefaultZipCodes={setDefaultZipCodes}
      />
    </Modal>
  );
};

type Props = {
  saveDefaultZipCodes: Function;
  setSelectedZipCodes: Function;
  setDefaultZipCodes: Function;
  defaultZipCodes: string[];
  selectedZipCodes: string[];
};

const AreaFilter = (props: Props): JSX.Element => {
  const [isOpen, setIsOpen] = useState(false);
  const {
    saveDefaultZipCodes,
    defaultZipCodes,
    selectedZipCodes,
    setSelectedZipCodes,
    setDefaultZipCodes,
  } = props;

  const selected = mapZipStr(selectedZipCodes);
  const groupedZips = grouped(selected);

  const isDefaultSelection =
    selectedZipCodes.sort().join(',') === defaultZipCodes.sort().join(',');

  return (
    <>
      <Wrapper
        onClick={() => {
          setIsOpen(!isOpen);
        }}
        id="areafilter-open"
      >
        <Box>
          <Pin />
        </Box>
        <AreaWrapper>
          {Object.keys(groupedZips).length ? (
            Object.entries(groupedZips).map(([k, v]) => (
              <AreaLabel key={k}>
                {k} ({v.length}) {'  '}
              </AreaLabel>
            ))
          ) : (
            <AreaLabel>Ei valittua aluetta</AreaLabel>
          )}
          <DefaultAreaMobile>
            {isDefaultSelection && 'Oletus'}
            {!isDefaultSelection && 'Mukautettu valinta'}
          </DefaultAreaMobile>
        </AreaWrapper>
        <ArrowWrapper>
          <Arrow />
        </ArrowWrapper>
      </Wrapper>
      <DefaultAreaDesktop>
        <LightText>
          {isDefaultSelection && 'Oletus'}
          {!isDefaultSelection && 'Mukautettu valinta'}
        </LightText>
      </DefaultAreaDesktop>
      <MarketAreaSelectorModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        selectedZipCodes={selected}
        saveDefaultZipCodes={saveDefaultZipCodes}
        setSelectedZipCodes={setSelectedZipCodes}
        setDefaultZipCodes={setDefaultZipCodes}
      />
    </>
  );
};

export default AreaFilter;
