import React from 'react';
import styled from 'styled-components';
import { ReactComponent as Building } from '../../assets/building.svg';
import {
  H4,
  Text,
  TextBold as TextBoldDefault,
} from '../../config/default-styles';
import { MeterProp } from '../../types';

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 0.5rem;
  padding: 0.5rem 1rem;
  cursor: pointer;
`;

const WrapperDesktop = styled.div`
  display: flex;
  flex-direction: column;
  color: ${props => props.theme.colors.text.white};
  margin-right: 3rem;
  cursor: pointer;
`;

const BuildingWrapper = styled.div`
  padding: 0.5rem;
  background-color: #e4f0ee;
  border-radius: 0.25rem;
`;

const Left = styled.div`
  display: flex;
  align-items: center;
`;

const Right = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

const Heading = styled.div`
  display: flex;
`;
const Content = styled.div`
  display: flex;
`;

const Row = styled.div`
  flex-direction: row;
`;

const RewardTextDesktop = styled.span`
  font-weight: 500;
  font-size: 0.6875rem;
  align-items: center;
  letter-spacing: 0.15rem;
  text-transform: uppercase;
  color: #bfd8d3;
`;

const TextBold = styled(TextBoldDefault)`
  border-bottom: 2px solid transparent;
  &:hover {
    border-bottom: 2px solid ${props => props.theme.colors.border.ascent};
  }
  &.selected {
    border-bottom: 2px solid ${props => props.theme.colors.border.ascent};
  }
`;

type DesktopProps = {
  selected: string;
};

export type Props = {
  marketingName: MeterProp;
  rewards: MeterProp;
  agency?: MeterProp;
  setStore: Function;
};

export const StoreDesktop = (props: Props & DesktopProps): JSX.Element => {
  return (
    <WrapperDesktop
      onClick={() => {
        props.setStore(props.marketingName.text);
      }}
    >
      <TextBold className={props.selected} style={{ whiteSpace: 'nowrap' }}>
        {props.marketingName.text}
      </TextBold>
      <Row>
        <RewardTextDesktop style={{ whiteSpace: 'nowrap' }}>
          Palkkiotuotot &nbsp;
          {props.rewards.num.toLocaleString('fi-FI', {
            style: 'currency',
            currency: 'EUR',
          })}
        </RewardTextDesktop>
      </Row>
    </WrapperDesktop>
  );
};

const StoreMobile = (props: Props): JSX.Element => {
  return (
    <Wrapper
      onClick={() => {
        props.setStore(props.marketingName.text);
      }}
    >
      <Left>
        <BuildingWrapper>
          <Building />
        </BuildingWrapper>
        <H4 style={{ marginLeft: '1rem' }}>{props.marketingName.text}</H4>
      </Left>
      <Right>
        <Heading>
          <Text>Palkkiotuotot</Text>
        </Heading>
        <Content>
          <TextBold>
            {props.rewards.num.toLocaleString('fi-FI', {
              style: 'currency',
              currency: 'EUR',
            })}
          </TextBold>
        </Content>
      </Right>
    </Wrapper>
  );
};

export default StoreMobile;
