import { createGlobalStyle } from 'styled-components';
import theme from './theme';

const GlobalStyle = createGlobalStyle`
  html, body, #root, #root>div {
    height: 100%;
    background-color: ${theme.colors.background.site};
  }

  html {
    font-size: 16px;
    line-height: 1.25;
  }
  body {
    height:100%;
    color: ${theme.colors.text.primary};
    font-family: Inter, sans-serif;
    padding: 0rem;
    margin: 0rem;
    height: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 100%;
  }

  *, *:before, *:after {
    box-sizing: border-box;
  }
`;

export default GlobalStyle;
