import styled from 'styled-components';

export const H1 = styled.h1`
  font-weight: bold;
  font-size: 1.3125rem;
`;
export const H2 = styled.h2`
  font-weight: bold;
  font-size: 1.125rem;
`;
export const H3 = styled.h3`
  font-weight: bold;
  font-size: 1rem;
`;
export const H4 = styled.h4`
  font-weight: bold;
  font-size: 0.875rem;
`;
export const H5 = styled.h5`
  font-weight: bold;
  font-size: 0.75rem;
`;
export const Text = styled.span`
  font-size: 0.8125rem;
  font-weight: normal;
`;

export const TextBold = styled.span`
  font-weight: bold;
  font-size: 0.8125rem;
`;

export const TextItalic = styled.span`
  font-style: italic;
  font-weight: normal;
  font-size: 0.8125rem;
`;

export const Small = styled.span`
  font-weight: normal;
  font-size: 0.6875rem;
`;

export const SmallBold = styled.span`
  font-weight: bold;
  font-size: 0.6875rem;
`;
