import React, { useState, useEffect, useContext, FC } from 'react';
import createAuth0Client, {
  Auth0ClientOptions,
  Auth0Client,
  IdToken,
  GetIdTokenClaimsOptions,
  RedirectLoginOptions,
  GetTokenSilentlyOptions,
  GetTokenWithPopupOptions,
  LogoutOptions,
} from '@auth0/auth0-spa-js';

interface Auth0ProviderProps {
  children: JSX.Element;
  auth0Options: Auth0ClientOptions;
  onRedirectCallback: Function;
}

export interface Auth0ContextType {
  isAuthenticated: boolean;
  user?: any;
  claims?: IdToken;
  loading: boolean;
  popupOpen: boolean;
  loginWithPopup: Function;
  handleRedirectCallback: Function;
  getIdTokenClaims: Function;
  loginWithRedirect: Function;
  getTokenSilently: Function;
  getTokenWithPopup: Function;
  logout: Function;
}

const defaultAuth0Context: Auth0ContextType = {
  isAuthenticated: false,
  loading: false,
  popupOpen: false,
  loginWithPopup: () => {},
  handleRedirectCallback: () => {},
  getIdTokenClaims: () => {},
  loginWithRedirect: () => {},
  getTokenSilently: () => {},
  getTokenWithPopup: () => {},
  logout: () => {},
};

const DEFAULT_REDIRECT_CALLBACK = () =>
  window.history.replaceState({}, document.title, window.location.pathname);

export const Auth0Context = React.createContext(defaultAuth0Context);
export const useAuth0 = () => useContext(Auth0Context);

export const Auth0Provider: FC<Auth0ProviderProps> = ({
  children,
  onRedirectCallback = DEFAULT_REDIRECT_CALLBACK,
  auth0Options,
}: Auth0ProviderProps) => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [user, setUser] = useState();
  const [auth0Client, setAuth0] = useState<Auth0Client>();
  const [loading, setLoading] = useState<boolean>(true);
  const [popupOpen, setPopupOpen] = useState<boolean>(false);

  useEffect(() => {
    const initAuth0 = async () => {
      const auth0FromHook = await createAuth0Client(auth0Options);
      setAuth0(auth0FromHook);

      if (
        window.location.search.includes('code=') &&
        window.location.search.includes('state=')
      ) {
        const { appState } = await auth0FromHook.handleRedirectCallback();
        (onRedirectCallback as any)(appState);
      }

      const isAuthenticated = await auth0FromHook.isAuthenticated();

      setIsAuthenticated(isAuthenticated);

      if (isAuthenticated) {
        const user = await auth0FromHook.getUser();
        setUser(user);
      }

      setLoading(false);
    };
    initAuth0();
  }, [auth0Options, onRedirectCallback]);

  if (!auth0Client) {
    return null;
  }

  const loginWithPopup = async () => {
    setPopupOpen(true);
    try {
      await auth0Client.loginWithPopup();
    } catch (error) {
      console.error(error);
    } finally {
      setPopupOpen(false);
    }
    const user = await auth0Client.getUser();
    setUser(user);
    setIsAuthenticated(true);
  };

  const handleRedirectCallback = async () => {
    setLoading(true);
    await auth0Client.handleRedirectCallback();
    const user = await auth0Client.getUser();
    setLoading(false);
    setIsAuthenticated(true);
    setUser(user);
  };

  return (
    <Auth0Context.Provider
      value={{
        isAuthenticated,
        user,
        loading,
        popupOpen,
        loginWithPopup,
        handleRedirectCallback,
        getIdTokenClaims: (options?: GetIdTokenClaimsOptions) =>
          auth0Client.getIdTokenClaims(options),
        loginWithRedirect: (options?: RedirectLoginOptions) =>
          auth0Client.loginWithRedirect(options),
        getTokenSilently: (options?: GetTokenSilentlyOptions) =>
          auth0Client.getTokenSilently(options),
        getTokenWithPopup: (options?: GetTokenWithPopupOptions) =>
          auth0Client.getTokenWithPopup(options),
        logout: (options?: LogoutOptions) => auth0Client.logout(options),
      }}
    >
      {children}
    </Auth0Context.Provider>
  );
};
