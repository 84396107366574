import React, { useState, useEffect } from 'react';
import moment from 'moment';
import styled from 'styled-components';

import AppHead from '../AppHead';
import AppBody from '../AppBody';
import Profit, { Props as ProfitProps } from '../Profit';
import Feedback, { Props as FeedbackProps } from '../FeedBack';
import { BlockLoader } from '../Loader';
import { MEASURES, DIMENSIONS, FIELDS } from '../../config/qlik-config';
import {
  profitMeasures,
  feedbackMeasures,
} from '../../config/qlik-config.measures';

import {
  MONEY_FORMAT,
  qlikHyperCube,
  qlikHyperCubeWithDimensions,
} from '../../utils/qlik-utils';
import Assignments from './Assignments';
import { HomeVisitsMobile, HomeVisitsDesktop, HomeVisit } from './HomeVisits';
import { MeterBlockHeadingWithTag } from '../MeterBlock';
import { usePersistedCalendarState } from '../../utils/persisted-state';

const Wrapper = styled.div`
  @media (min-width: ${props => props.theme.break.min}) {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
  }
`;

const Desktop = styled.div`
  @media (max-width: ${props => props.theme.break.max}) {
    display: none;
  }
`;
const Mobile = styled.div`
  @media (min-width: ${props => props.theme.break.min}) {
    display: none;
  }
`;

const DesktopLeft = styled.div`
  @media (min-width: ${props => props.theme.break.min}) {
    width: 21rem;
  }
`;

const StyledBlockLoader = styled(BlockLoader)``;

const DesktopRight = styled.div`
  ${StyledBlockLoader} {
    height: 50rem;
  }

  @media (min-width: ${props => props.theme.break.min}) {
    flex: 1 1 0%;
    margin-left: 1rem;

    ${StyledBlockLoader} {
      height: 26rem;
    }
  }
`;

const getGreeting = (claims: any) => {
  const name = claims?.email.split('@')[0].split('.')[0];
  return `Hei ${name?.slice(0, 1).toUpperCase()}${name?.slice(1)}!`;
};

const RealtorView = (props: any) => {
  const { app, claims } = props;
  // other
  const [dateRange, setDateRange] = usePersistedCalendarState('daterange');
  const [hideAssignments, setHideAssignments] = useState<boolean>(true);
  const [hideHomeVisits, setHideHomeVisits] = useState<boolean>(true);

  // cube states
  const [profitCube, setProfitCube] = useState<ProfitProps | undefined>(
    undefined,
  );
  const [feedbackCube, setFeedbackCube] = useState<FeedbackProps | undefined>(
    undefined,
  );
  const [assignmentsCube, setAssignmentsCube] = useState<
    any[] | undefined // NOTE: swap to this once typings are done for mapTableProps [Assignment] | undefined
  >(undefined);
  const [activeAssignmentsCube, setActiveAssignmentsCube] = useState<
    [any] | undefined
  >(undefined);
  const [soldAssignmentsCube, setSoldAssignmentsCube] = useState<
    [any] | undefined
  >(undefined);
  const [homeVisitsCube, setHomeVisitsCube] = useState<[HomeVisit] | undefined>(
    undefined,
  );

  // get cubes on load
  useEffect(() => {
    if (!app) {
      return;
    }
    qlikHyperCube(app, feedbackMeasures, {}, setFeedbackCube);
    qlikHyperCube(
      app,
      [[MEASURES['Palkkiot'], 'rewards'], ...profitMeasures],
      MONEY_FORMAT,
      setProfitCube,
    );
    // spread?
    qlikHyperCubeWithDimensions(
      app,
      [
        [DIMENSIONS['Toimeksiannon kohdenumero'], 'id'],
        [DIMENSIONS['Toimeksiannon tila'], 'status'],
        [DIMENSIONS['Toimeksiannon osoite'], 'address'],
        [DIMENSIONS['Kauppatapa'], 'tradeMethod'],
        [DIMENSIONS['ToimeksiantosopimuksenPäättymispäivä'], 'endDate'],
        [DIMENSIONS['Toimeksiannon Odotettu palkkio'], 'reward'],
      ],
      [
        [
          MEASURES['Yleisesittelyt toimeksiantoihin (Ei myyty)'],
          'publicDisplays',
        ],
        [
          MEASURES['Yksittäisesittelyt toimeksiantoihin (Ei myyty)'],
          'privateDisplays',
        ],
        [MEASURES['Tarjoukset toimeksiantoihin kpl (Ei Myyty)'], 'offers'],
        [MEASURES['Yhteydenottopyynnöt'], 'contacts'],
        [
          MEASURES['Asiakkaita näytöllä toimeksiantoihin (Ei myyty)'],
          'peopleCount',
        ],
        [MEASURES['Toimeksiannon Katselut portaaleissa'], 'viewsInPortals'],
        [
          MEASURES['Tarjoukset toimeksiantoihin kpl (Välittäjä)'],
          'realtorOffers',
        ],
        [
          MEASURES['Yleisesittelyt toimeksiantoihin (Välittäjä)'],
          'realtorPublicDisplays',
        ],
        [
          MEASURES['Asiakkaita näytöllä toimeksiantoihin (Välittäjä)'],
          'realtorPeopleCount',
        ],
        [
          MEASURES['Yksittäisesittelyt toimeksiantoihin (Välittäjä)'],
          'realtorPrivateDisplays',
        ],
      ],
      {},
      setActiveAssignmentsCube,
    );

    qlikHyperCubeWithDimensions(
      app,
      [
        [DIMENSIONS['Toimeksiannon kohdenumero'], 'id'],
        [DIMENSIONS['Toimeksiannon tila'], 'status'],
        [DIMENSIONS['Toimeksiannon osoite'], 'address'],
        [DIMENSIONS['Kauppatapa'], 'tradeMethod'],
        [DIMENSIONS['MyyntiPvm'], 'endDate'],
      ],
      [
        [MEASURES['Toimeksiannon Odotettu Palkkio (Myyty)'], 'reward'],
        [MEASURES['Yleisesittelyt toimeksiantoihin (Myyty)'], 'publicDisplays'],
        [
          MEASURES['Yksittäisesittelyt toimeksiantoihin (Myyty)'],
          'privateDisplays',
        ],
        [MEASURES['Tarjoukset toimeksiantoihin kpl (Myyty)'], 'offers'],
        [MEASURES['Yhteydenottopyynnöt'], 'contacts'],
        [
          MEASURES['Asiakkaita näytöllä toimeksiantoihin (Myyty)'],
          'peopleCount',
        ],
        [MEASURES['Toimeksiannon Katselut portaaleissa'], 'viewsInPortals'],
        [
          MEASURES['Tarjoukset toimeksiantoihin kpl (Välittäjä)'],
          'realtorOffers',
        ],
        [
          MEASURES['Yleisesittelyt toimeksiantoihin (Välittäjä)'],
          'realtorPublicDisplays',
        ],
        [
          MEASURES['Asiakkaita näytöllä toimeksiantoihin (Välittäjä)'],
          'realtorPeopleCount',
        ],
        [
          MEASURES['Yksittäisesittelyt toimeksiantoihin (Välittäjä)'],
          'realtorPrivateDisplays',
        ],
      ],
      {},
      setSoldAssignmentsCube,
    );

    qlikHyperCubeWithDimensions(
      app,
      [
        [DIMENSIONS['Kotikäynnin Osoite'], 'address'],
        [DIMENSIONS['Kotikäynnin Asiakas'], 'customer'],
        [DIMENSIONS['Kotikäynnin Status'], 'status'],
        [DIMENSIONS['Pvm'], 'date'],
      ],
      [],
      {},
      setHomeVisitsCube,
    );
  }, [app]);

  useEffect(() => {
    if (activeAssignmentsCube && soldAssignmentsCube) {
      setAssignmentsCube([...activeAssignmentsCube, ...soldAssignmentsCube]);
    }
  }, [activeAssignmentsCube, soldAssignmentsCube]);

  useEffect(() => {
    if (app) {
      app.field(FIELDS.Pvm, '$').clear();
      const { startDate, endDate } = dateRange;
      const rangeStart = moment(startDate).format('D.M.YYYY');
      const rangeEnd = moment(endDate).format('D.M.YYYY');
      app
        .field(FIELDS.Pvm, '$')
        .selectMatch(`>=${rangeStart}<=${rangeEnd}`, true);
    }
  }, [dateRange, app]);

  return (
    <>
      <AppHead
        showStores={false}
        setSelectedStore={undefined}
        selectedStore={undefined}
        dateRange={dateRange}
        setDateRange={setDateRange}
        claims={claims}
        name={getGreeting(claims)}
      />
      <AppBody>
        <Wrapper>
          <DesktopLeft>
            {profitCube ? (
              <Profit {...profitCube} heading="Oma tulos ja toiminta" />
            ) : (
              <BlockLoader height={'37rem'} />
            )}
            {feedbackCube ? (
              <Feedback {...feedbackCube} />
            ) : (
              <BlockLoader height={'10.5rem'} />
            )}
          </DesktopLeft>
          <DesktopRight>
            {assignmentsCube ? (
              <Assignments
                data={assignmentsCube}
                showAll={!hideAssignments}
                onHide={() => {
                  setHideAssignments(!hideAssignments);
                }}
              />
            ) : (
              <StyledBlockLoader />
            )}

            <Mobile>
              {!homeVisitsCube && <BlockLoader height={'50rem'} />}
              {homeVisitsCube && (
                <>
                  <MeterBlockHeadingWithTag
                    tag={homeVisitsCube.length}
                    action={setHideHomeVisits}
                    hideAll={hideHomeVisits}
                    itemCount={homeVisitsCube.length}
                  >
                    Käydyt kotikäynnit
                  </MeterBlockHeadingWithTag>
                  <HomeVisitsMobile
                    hideHomeVisits={hideHomeVisits}
                    homeVisits={homeVisitsCube}
                  />
                </>
              )}
            </Mobile>
            <Desktop>
              {!homeVisitsCube && <BlockLoader height={'26rem'} />}
              {homeVisitsCube && (
                <HomeVisitsDesktop
                  hideHomeVisits={hideHomeVisits}
                  homeVisits={homeVisitsCube}
                  setHideHomeVisits={setHideHomeVisits}
                />
              )}
            </Desktop>
          </DesktopRight>
        </Wrapper>
      </AppBody>
    </>
  );
};

export default RealtorView;
