import React from 'react';
import styled from 'styled-components';

import Table, { Td, Th, Tr, MainViewTableBlock } from '../../Tables';
import PeopleBadge from '../../Badges';
import { Tag, ShowMore } from '../../MeterBlock';
import { H2, Text, TextBold } from '../../../config/default-styles';
import { ProfileImageWithInitals } from '../../ProfileImage';
import { TrendNoArrow, getTrend, getTrendPercent } from '../../Trends';
import { Props, StaffMember } from './index';

const HeadingRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${props => props.theme.colors.background.white};
  padding: 1rem 1rem 0.5rem;
  border-radius: inherit;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Column = styled.div`
  flex-direction: column;
`;

const TdRealtor = (props: StaffMember): JSX.Element => {
  return (
    <Td>
      <Row>
        <ProfileImageWithInitals name={props.realtor.text} />
        <div style={{ marginLeft: '1rem' }}>
          <Column style={{ minWidth: '13rem' }}>
            <div>
              <TextBold>{props.realtor.text}</TextBold>
            </div>
            <TextBold>
              {props.rewards.num.toLocaleString('fi-FI', {
                style: 'currency',
                currency: 'EUR',
              })}{' '}
              /{' '}
              {props.salesPcs.num
                .toFixed(2)
                .replace('.', ',')
                .replace(',00', '')}{' '}
              kpl
            </TextBold>{' '}
            <Text>myynnistä</Text>
          </Column>
        </div>
        <div style={{ alignSelf: 'flex-end' }}>
          <TrendNoArrow
            trend={getTrend(props.rewards.num, props.rewardsLastYear.num)}
            trendPercent={getTrendPercent(
              props.rewards.num,
              props.rewardsLastYear.num,
            )}
          />
        </div>
      </Row>
    </Td>
  );
};

const TdCalls = (props: StaffMember): JSX.Element => {
  return (
    <Td>
      <Row>
        <Text>{props.calls.text}</Text>
        <div>
          <TrendNoArrow
            style={{ width: '3rem', textAlign: 'right' }}
            trend={getTrend(props.calls.num, props.callsLastYear.num)}
            trendPercent={getTrendPercent(
              props.calls.num,
              props.callsLastYear.num,
            )}
          />
        </div>
      </Row>
    </Td>
  );
};

const TdHomeVisits = (props: StaffMember): JSX.Element => {
  return (
    <Td>
      <Row>
        <Text>{props.homeVisits.text}</Text>
        <div>
          <TrendNoArrow
            trendPercent={getTrendPercent(
              props.homeVisits.num,
              props.homeVisitsLastYear.num,
            )}
            trend={getTrend(props.homeVisits.num, props.homeVisitsLastYear.num)}
          />
        </div>
      </Row>
    </Td>
  );
};

const TdAquisitions = (props: StaffMember): JSX.Element => {
  return (
    <Td>
      <Row>
        <Text>{props.acquisitions.text}</Text>
        <div>
          <TrendNoArrow
            trendPercent={getTrendPercent(
              props.acquisitions.num,
              props.acquisitionLastYear.num,
            )}
            trend={getTrend(
              props.acquisitions.num,
              props.acquisitionLastYear.num,
            )}
          />
        </div>
      </Row>
    </Td>
  );
};

const TdDisplays = (props: StaffMember): JSX.Element => {
  return (
    <Td>
      <div>
        <Row style={{ justifyContent: 'space-between' }}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              width: '8rem',
            }}
          >
            <div>
              <TextBold>{props.publicDisplays.text}</TextBold>{' '}
              <Text>yleistä</Text>{' '}
            </div>
            <PeopleBadge count={props.peopleInPublicDisplays.text} />
          </div>
          <div style={{ textAlign: 'right' }}>
            <TrendNoArrow
              trendPercent={getTrendPercent(
                props.publicDisplays.num,
                props.publicDisplaysLastYear.num,
              )}
              trend={getTrend(
                props.acquisitions.num,
                props.acquisitionLastYear.num,
              )}
            />
          </div>
        </Row>
        <Row style={{ justifyContent: 'space-between' }}>
          <div>
            <TextBold>{props.privateDisplays.text}</TextBold>{' '}
            <Text>yksityistä</Text>
          </div>
          <div style={{ textAlign: 'right' }}>
            <TrendNoArrow
              trendPercent={getTrendPercent(
                props.privateDisplays.num,
                props.privateDisplaysLastYear.num,
              )}
              trend={getTrend(
                props.privateDisplays.num,
                props.privateDisplaysLastYear.num,
              )}
            />
          </div>
        </Row>
      </div>
    </Td>
  );
};

type DesktopProps = {
  setHideStaff: Function;
};

const StaffDesktop = (props: Props & DesktopProps): JSX.Element => {
  const { staff, hideStaff, setHideStaff, staffCount } = props;
  const objs = hideStaff ? staff.slice(0, 5) : staff;
  return (
    <MainViewTableBlock>
      <HeadingRow>
        <Row>
          <H2>Henkilökunta</H2>
          <Tag style={{ marginLeft: '1rem' }}>{staffCount}</Tag>
        </Row>
        <div
          onClick={() => {
            setHideStaff(!hideStaff);
          }}
        >
          <TextBold>
            <ShowMore
              action={setHideStaff}
              hideAll={hideStaff}
              itemCount={staff.length}
            />
          </TextBold>
        </div>
      </HeadingRow>
      <Table>
        <thead>
          <Tr>
            <Th>
              <Text>Välittäjä / Palkkiotuotot</Text>
            </Th>
            <Th>
              <Text>Puhelut</Text>
            </Th>
            <Th>
              <Text>Kotikäynnit</Text>
            </Th>
            <Th>
              <Text>Toimeksiannot</Text>
            </Th>
            <Th>
              <Text>Esittelyt</Text>
            </Th>
          </Tr>
        </thead>
        <tbody>
          {objs.map(m => (
            <Tr key={m.realtor.text}>
              <TdRealtor {...m} />
              <TdCalls {...m} />
              <TdHomeVisits {...m} />
              <TdAquisitions {...m} />
              <TdDisplays {...m} />
            </Tr>
          ))}
        </tbody>
      </Table>
    </MainViewTableBlock>
  );
};

export default StaffDesktop;
