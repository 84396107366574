import React from 'react';
import ContentLoader from 'react-content-loader';
import styled from 'styled-components';

const Wrapper = styled.div`
  margin-bottom: 1rem;
`;

type Props = {
  height?: string;
  className?: string;
};

export const HeaderLoader = (): JSX.Element => (
  <ContentLoader
    speed={2}
    width={'10rem'}
    height={'2rem'}
    backgroundColor="#e6eBee"
    foregroundColor="#fff"
    style={{ borderRadius: '0.5rem', marginBottom: '0.5rem' }}
  >
    <rect x="0" y="0" rx="0" ry="0" width="10rem" height="2rem" />
  </ContentLoader>
);

export const BlockLoader = ({ height, ...rest }: Props): JSX.Element => (
  <>
    <ContentLoader
      speed={2}
      width={'100%'}
      height={height || '100%'}
      backgroundColor="#e6eBee"
      foregroundColor="#fff"
      style={{ borderRadius: '0.5rem', marginBottom: '0.5rem' }}
      {...rest}
    >
      <rect x="0" y="0" rx="0" ry="0" width="100%" height="100%" />
    </ContentLoader>
  </>
);

export const DoubleLoader = (): JSX.Element => (
  <Wrapper>
    <HeaderLoader />
    <BlockLoader />
    <BlockLoader />
  </Wrapper>
);

export default DoubleLoader;
