import * as Sentry from '@sentry/react';
import QlikDependencies from './qlik-dependencies';

export default async (id, config) => {
  const { isSecure, host, port, prefix } = config;

  const protocol = isSecure ? 'https://' : 'http://';
  const portStr = port ? `:${port}` : '';

  const baseUrl = `${protocol}${host}${portStr}${prefix}resources`;
  const requireConfig = {
    baseUrl,
  };

  const qlik = await QlikDependencies(requireConfig);

  if (qlik) {
    // attach a global error handler for the session
    // timeouts which might happen
    // https://extendingqlik.upper88.com/handle-errors-in-your-qlik-sense-mashup/
    qlik.setOnError(err => {
      if (
        err.code === 16 ||
        [
          'OnEngineWebsocketFailed',
          'OnLicenseAccessDenied',
          'OnNoEngineAvailable',
          'OnNoDataPrepServiceAvailable',
          'OnDataPrepServiceWebsocketFailed',
          'OnSessionTimedOut',
        ].indexOf(err.method) > -1
      ) {
        console.error(err);
        Sentry.captureMessage(err);
        window.location.reload(); // forces reload
      }
    });
    return qlik.openApp(id, config);
  }
  return null;
};
