import React, { useState } from 'react';
import styled from 'styled-components';
import Modal from './Modal';
import Profile from './Profile';

const style = {
  content: {
    top: '0%',
    left: '3%',
    right: 'auto',
    bottom: 'auto',
    margin: '0',
    padding: '0',
    width: '97%',
    border: 'none',
  },
  overlay: {
    background: 'rgba(0, 0, 0, 0.5)',
    zIndex: 9999,
  },
};

const ProfileImageWrapper = styled.div`
  & > img {
    height: 2.5rem;
    border-radius: 1.25rem;
  }
`;

const ProfileImageWithInitalsWrapper = styled.div`
  height: 2.5rem;
  width: 2.5rem;
  border-radius: 1.25rem;
  background-color: ${props => props.theme.colors.background.primary};
  color: ${props => props.theme.colors.text.white};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ProfileImageWithInitalsHeaderWrapper = styled(
  ProfileImageWithInitalsWrapper,
)`
  color: ${props => props.theme.colors.text.primary};
  background-color: ${props => props.theme.colors.background.secondary};
  cursor: pointer;

  width: 2.25rem;
  height: 2.25rem;
  border-radius: 1.125rem;
`;

const ProfileImageWithInitalsLargeWrapper = styled(
  ProfileImageWithInitalsWrapper,
)`
  margin-top: 6.5rem;
  height: 5rem;
  width: 5rem;
  border-radius: 2.5rem;
  color: ${props => props.theme.colors.text.primary};
  background-color: ${props => props.theme.colors.background.secondary};
`;

const getNameFromEmail = (claims: any): string => {
  const nick = claims?.nickname;
  const bits = nick ? nick.split('.') : [];
  return bits
    .map((x: string) => `${x.charAt(0).toUpperCase()}${x.slice(1)}`)
    .join(' ');
};

const getInitialsFromName = (name: string): string => {
  return name
    .split(' ')
    .map((x: string) => x.slice(0, 1))
    .join('');
};

export const ProfileImageWithInitalsHeader = (props: { claims: any }) => {
  const { claims } = props;
  const name = getNameFromEmail(claims);
  const initials = getInitialsFromName(name);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  return (
    <>
      <ProfileImageWithInitalsHeaderWrapper
        onClick={() => {
          setIsOpen(true);
        }}
      >
        {initials}
      </ProfileImageWithInitalsHeaderWrapper>
      <Modal isOpen={isOpen} style={style}>
        <Profile claims={claims} close={setIsOpen} />
      </Modal>
    </>
  );
};

export const ProfileImageWithInitalsLarge = (props: { claims: any }) => {
  const { claims } = props;
  const name = getNameFromEmail(claims);
  const initials = getInitialsFromName(name);

  return (
    <ProfileImageWithInitalsLargeWrapper>
      {initials}
    </ProfileImageWithInitalsLargeWrapper>
  );
};

export const ProfileImageWithInitals = (props: { name: string }) => (
  <ProfileImageWithInitalsWrapper>
    {props.name
      .split(' ')
      .map((n: string) => n[0])
      .join('')}
  </ProfileImageWithInitalsWrapper>
);

export default (props: any) => (
  <ProfileImageWrapper>
    <img src={props.image} alt="me" />
  </ProfileImageWrapper>
);
