export const breakpoint = 1330;

const palette = {
  lightGreen: '#23c278',
  green: '#00A050',
  darkGreen: '#006450',
  orange: '#FFAB00',
  red: '#C54F4F',
  purple: '#8C50B4',
  turquoise: '#78B4DC',
  unknown: '#909090',
};

export default {
  colors: {
    base: 'light',
    background: {
      primary: '#006450',
      secondary: '#f4f5f7',
      tertiary: '#e6ebee',
      white: '#fff',
      ascent: '#32d17b',
      green: '#e6f0ee',
      site: '#f4f5f7',
      warning: palette.orange,
    },
    border: {
      primary: '#e6ebee',
      ascent: palette.lightGreen,
    },
    text: {
      primary: '#383838',
      secondary: '#707070',
      ascent: '#006450',
      white: '#fff',
      green: '#006450',
      red: '#c54f4f',
    },
    status: {
      warning: palette.orange,
      error: palette.red,
      disabled: palette.unknown,
      progress: palette.turquoise,
      success: palette.green,
    },
  },
  break: {
    min: `${breakpoint}px`,
    max: `${breakpoint - 1}px`,
  },
};
