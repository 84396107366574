import React from 'react';

import MeterBlock from './MeterBlock';
import {
  MeterHeading,
  MeterPrimary,
  MeterPrimaryTwoLines,
} from './MeterPrimary';
import { MeterSecondary, MeterHrSecondary } from './MeterSecondary';
import { getTrendPercent, getTrend } from './Trends';
import { MeterProp } from '../types';

export type Props = {
  heading: string;
  rewards: MeterProp;
  rewardsLastYear: MeterProp;
  salesPcs: MeterProp;
  homeVisits: MeterProp;
  homeVisitsLastYear: MeterProp;
  acquisitions: MeterProp;
  acquisitionLastYear: MeterProp;
  calls: MeterProp;
  callsLastYear: MeterProp;
  homeSearches: MeterProp;
  homeSearchesLastYear: MeterProp;
  privateDisplays: MeterProp;
  privateDisplaysLastYear: MeterProp;
  publicDisplays: MeterProp;
  publicDisplaysLastYear: MeterProp;
  customerDirectionsPcs: MeterProp;
  customerDirectionsPcsLastYear: MeterProp;
  offersLastYearPcs: MeterProp;
  offersPcs: MeterProp;
};

export default (props: Props): JSX.Element => {
  return (
    <MeterBlock>
      <MeterHeading>{props.heading}</MeterHeading>
      <MeterPrimaryTwoLines
        first={{
          primary: `${props.rewards.text}`,
          secondary: `${props.salesPcs.num.toFixed()} kpl`,
          tertiary: 'myynnistä',
        }}
        second={{
          primary: '',
          secondary: props.offersPcs.num.toFixed(),
          tertiary: 'tarjousta',
        }}
        legend="palkkiotuotot"
        trend={getTrend(props.rewards.num, props.rewardsLastYear.num)}
        trendPercent={getTrendPercent(
          props.rewards.num,
          props.rewardsLastYear.num,
        )}
      />
      {/* <MeterPrimaryWithLabel
        currentPeriod={props.homeVisits.num.toFixed()}
        legend="kotikäynnit"
        trend={getTrend(props.homeVisits.num, props.homeSearchesLastYear.num)}
        trendPercent={getTrendPercent(
          props.homeVisits.num,
          props.homeSearchesLastYear.num,
        )}
        label={{
          primary: ' 60%',
          secondary: 'voitettu',
        }}
      /> */}
      <MeterPrimary
        currentPeriod={props.homeVisits.num.toFixed()}
        legend="kotikäynnit"
        trend={getTrend(props.homeVisits.num, props.homeSearchesLastYear.num)}
        trendPercent={getTrendPercent(
          props.homeVisits.num,
          props.homeSearchesLastYear.num,
        )}
      />
      <MeterPrimary
        currentPeriod={props.acquisitions.num.toFixed()}
        legend="hankinnat"
        trend={`${getTrend(
          props.acquisitions.num,
          props.acquisitionLastYear.num,
        )}`}
        trendPercent={getTrendPercent(
          props.acquisitions.num,
          props.acquisitionLastYear.num,
        )}
      />
      <MeterHrSecondary />
      <MeterSecondary
        currentPeriod={props.homeSearches.num.toFixed()}
        legend="kotihakua"
        trendPercent={getTrendPercent(
          props.homeSearches.num,
          props.homeSearchesLastYear.num,
        )}
        trend={getTrend(props.homeSearches.num, props.homeSearchesLastYear.num)}
      />
      <MeterHrSecondary />
      <MeterSecondary
        currentPeriod={props.privateDisplays.num.toFixed()}
        legend="asiakkaat yksityisnäytöllä"
        trendPercent={getTrendPercent(
          props.privateDisplays.num,
          props.privateDisplaysLastYear.num,
        )}
        trend={getTrend(
          props.privateDisplays.num,
          props.privateDisplaysLastYear.num,
        )}
      />
      <MeterSecondary
        currentPeriod={props.publicDisplays.num.toFixed()}
        legend="asiakkaat yleisnäytöllä"
        trendPercent={getTrendPercent(
          props.publicDisplays.num,
          props.publicDisplaysLastYear.num,
        )}
        trend={getTrend(
          props.publicDisplays.num,
          props.publicDisplaysLastYear.num,
        )}
      />
      <MeterHrSecondary />
      <MeterSecondary
        currentPeriod={props.customerDirectionsPcs.num.toFixed()}
        legend="asiakasohjausta"
        trendPercent={getTrendPercent(
          props.customerDirectionsPcs.num,
          props.customerDirectionsPcsLastYear.num,
        )}
        trend={getTrend(
          props.customerDirectionsPcs.num,
          props.customerDirectionsPcsLastYear.num,
        )}
      />
    </MeterBlock>
  );
};
