import React, { useState } from 'react';
import styled, { keyframes } from 'styled-components';

import { H2 } from '../config/default-styles';
import { ReactComponent as Arrow } from '../assets/right-arrow.svg';

export const Tag = styled.span`
  padding: 0.25rem;
  background-color: ${props => props.theme.colors.background.tertiary};
  border-radius: 0.25rem;
  text-align: center;
  min-width: 26px;
`;

const Action = styled.span`
  float: right;
  font-weight: bold;
  text-transform: none;
  letter-spacing: 0rem;
  color: ${props => props.theme.colors.text.ascent};
  cursor: pointer;
`;

export const MeterBlockHeading = styled(H2)`
  font-weight: bold;
  font-size: 12px;
  letter-spacing: 0.15em;
  text-transform: uppercase;
  color: ${p => p.theme.colors.text.secondary};
  margin-top: 2.6875rem;
`;

const rotateDown = keyframes`
  from { transform: rotate(90deg); }
  to { transform: rotate(270deg); }
`;

const rotateUp = keyframes`
  from { transform: rotate(270deg);}
  to { transform: rotate(90deg); }
`;

const DownArrow = styled(Arrow)`
  display: inline;
  margin-left: 0.5rem;
  transform: rotate(90deg);
`;

const DownArrowAnimated = styled(DownArrow)`
  animation: ${rotateUp} 0.2s linear;
`;

const UpArrowAnimated = styled(DownArrow)`
  animation: ${rotateDown} 0.2s linear;
`;

type ShowMoreProps = {
  hideAll: boolean;
  action: Function;
  itemCount: number; // total number of items
  head?: number; // number of visible items
};

export const ShowMore = (props: ShowMoreProps): JSX.Element | null => {
  const [clicked, setClicked] = useState<boolean>(false);

  if (props.head && props.itemCount <= props.head) {
    return null;
  }

  return (
    <>
      {props.hideAll && props.itemCount !== 0 && (
        <Action
          onClick={() => {
            setClicked(true);
            props.action(!props.hideAll);
          }}
        >
          Näytä kaikki {!clicked ? <DownArrow /> : <DownArrowAnimated />}
        </Action>
      )}
      {!props.hideAll && props.itemCount !== 0 && (
        <Action
          onClick={() => {
            props.action(!props.hideAll);
          }}
        >
          Näytä vähemmän{' '}
          <UpArrowAnimated style={{ transform: 'rotate(270deg)' }} />
        </Action>
      )}
    </>
  );
};

type HeadingProps = {
  tag: string | number;
  children: any;
};

export const MeterBlockHeadingWithTag = (
  props: HeadingProps & ShowMoreProps,
): JSX.Element => (
  <MeterBlockHeading>
    {props.children} <Tag>{props.tag}</Tag>
    <ShowMore {...props} />
  </MeterBlockHeading>
);

export const Wrapper = styled.div`
  border-radius: 0.5rem;
  box-shadow: 0px 20px 20px rgba(222, 232, 238, 0.3);
  background-color: ${props => props.theme.colors.background.white};
  margin-bottom: 1rem;

  .meter {
    &:last-child {
      border-radius: 0 0 0.5rem 0.5rem;
    }

    &:first-child {
      border-radius: 0.5rem 0.5rem 0 0;
    }
  }

  .secondary {
    &:last-child {
      padding-bottom: 1rem;
    }
  }
`;

const MeterBlock = (props: { children: any }): JSX.Element => (
  <Wrapper>{props.children}</Wrapper>
);

export default MeterBlock;
