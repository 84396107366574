const loadDependancy = dep => {
  return new Promise(resolve => {
    window.require([dep], x => {
      resolve(x);
    });
  });
};

const loadJs = path => {
  return new Promise(resolve => {
    const head = document.getElementsByTagName('head')[0];
    const script = document.createElement('script');
    script.src = path;
    head.appendChild(script);
    script.onload = () => {
      resolve();
    };
  });
};

// const loadCss = path => {
//   return new Promise(resolve => {
//     const head = document.getElementsByTagName('head')[0];
//     const link = document.createElement('link');
//     link.rel = 'stylesheet';
//     link.href = path;
//     head.appendChild(link);
//     link.onload = () => {
//       resolve();
//     };
//   });
// };

export default async requireConfig => {
  // await loadCss(`${requireConfig.baseUrl}/autogenerated/qlik-styles.css`);
  await loadJs(`${requireConfig.baseUrl}/assets/external/requirejs/require.js`);
  window.require.config(requireConfig);
  const qlik = await loadDependancy('js/qlik');
  return qlik;
};
