import React from 'react';
import styled from 'styled-components';
import { ReactComponent as LogoSVG } from '../assets/logo.svg';
import { ReactComponent as LogoText } from '../assets/logo-text.svg';
import { ReactComponent as Close } from '../assets/close-white.svg';
import { ReactComponent as Info } from '../assets/info.svg';
import { ProfileImageWithInitalsLarge } from './ProfileImage';
import LogoutButton from './Button';
import Impersonate from './Impersonate/';
import { isImpersonating, canImpersonate } from './Impersonate/utils';
import { useAuth0 } from '../react-auth0-spa';
import { H3, Text } from '../config/default-styles';

const Wrapper = styled.div`
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  background: ${props => props.theme.colors.background.primary};
  color: ${props => props.theme.colors.text.white};
  height: 100vh;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  flex-shrink: 0;
`;

const Center = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  flex-grow: 1;
  max-height: 70%;
`;

const Footer = styled.div`
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
`;

interface ProfileProps {
  claims: any;
  close: Function;
}

const Profile = (props: ProfileProps): JSX.Element | null => {
  const { claims, close } = props;
  const { user, logout } = useAuth0();

  if (!user) {
    return null;
  }

  const { protocol, hostname, port } = window.location;
  // just make sure this is in auth0 as well
  const returnTo = `${protocol}//${hostname}:${port}`;

  return (
    <Wrapper>
      <Header>
        <div>
          <LogoSVG style={{ marginRight: '0.75rem' }} />
          <LogoText />
        </div>
        <Close
          onClick={() => {
            close();
          }}
          style={{ cursor: 'pointer' }}
        />
      </Header>
      <Center>
        <ProfileImageWithInitalsLarge claims={claims} />
        <H3 style={{ wordBreak: 'break-word' }}>{user.email}</H3>
        <LogoutButton
          onClick={() => {
            sessionStorage.clear();
            logout({
              returnTo,
            });
          }}
        >
          Kirjaudu ulos
        </LogoutButton>
        {canImpersonate(user) ? (
          <Impersonate
            claims={claims}
            isImpersonating={isImpersonating(user)}
            {...user}
          />
        ) : null}
      </Center>
      <Footer>
        <div style={{ marginRight: '1rem' }}>
          <Info />
        </div>
        <div>
          <Text>
            Hei! Palvelua kehitetään jatkuvasti. Pysy kuulolla uusista
            toiminnallisuuksista.
          </Text>
        </div>
      </Footer>
    </Wrapper>
  );
};

export default Profile;
