import React from 'react';
import styled from 'styled-components';

import { Text } from '../config/default-styles';
import { Hr } from './MeterPrimary';
import { TrendSecondary } from './Trends';

const CurrentSecondary = styled.div`
  font-weight: bold;
  font-size: 0.875rem;
`;

const LegendSecondary = styled.div`
  margin-left: 0.25rem;
  font-size: 0.8125rem;
`;

const WrapperSecondary = styled.div`
  display: flex;
  background: #f9fafb;
  justify-content: space-between;
  padding: 1rem 1rem 0rem 1rem;
`;

const LeftSecondary = styled.div`
  display: flex;
`;

const RightSecondary = styled.div`
  display: flex;
`;

const HrWrapperSecondary = styled.div`
  background-color: #f9fafb;
  padding: 1rem 1rem 0rem 1rem;
  margin: 0;
`;

export const MeterHrSecondary = () => (
  <HrWrapperSecondary>
    <Hr />
  </HrWrapperSecondary>
);

type Props = {
  style?: any;
  trend?: string;
  trendPercent?: string | number;
  legend: string;
  currentPeriod: string | number;
};

export const MeterSecondary = (props: Props): JSX.Element => (
  <WrapperSecondary className="meter secondary" style={{ ...props.style }}>
    <LeftSecondary>
      <CurrentSecondary>{props.currentPeriod}</CurrentSecondary>
      <LegendSecondary>
        <Text>{props.legend}</Text>
      </LegendSecondary>
    </LeftSecondary>
    <RightSecondary>
      {props.trendPercent && (
        <TrendSecondary trendPercent={props.trendPercent} trend={props.trend} />
      )}
    </RightSecondary>
  </WrapperSecondary>
);
