import React from 'react';
import styled from 'styled-components';

import { ReactComponent as GreenArrow } from '../assets/ne-green-arrow.svg';
import { ReactComponent as RedArrow } from '../assets/se-red-arrow.svg';
import { ReactComponent as BlueArrow } from '../assets/e-blue-arrow.svg';

export enum Trend {
  Positive = 'positive',
  Negative = 'negative',
  NoChange = 'no-change',
  NoTrend = 'no-trend',
}

export const getTrend = (currentPeriod: number, pastPeriod: number): Trend => {
  if (currentPeriod === pastPeriod) {
    return Trend.NoChange;
  }
  if (currentPeriod > pastPeriod) {
    return Trend.Positive;
  }
  return Trend.Negative;
};

export const getTrendPercent = (
  currentPeriod: number,
  pastPeriod: number,
): string => {
  const result = Math.abs(((currentPeriod - pastPeriod) / pastPeriod) * 100);

  if (isNaN(result)) {
    return '0'; // zero divided by zero
  }

  if (!isFinite(result)) {
    return '&infin;'; // division by zero
  }

  return result.toFixed();
};

const TrendWrapperPrimary = styled.div`
  padding: 0rem 0.5rem;
  display: flex;
  align-items: center;
  border-radius: 0.25rem;
  justify-content: space-between;
  min-width: 4rem;
  height: 1.5rem;
  font-weight: bold;
  font-size: 0.75rem;

  &:only-child.positive {
    background-color: #e6f6ee;
    color: #00a050;
  }

  &:only-child.negative {
    background-color: #f9eded;
    color: #c54f4f;
  }

  &:only-child.no-change {
    background-color: #f2f7fb;
    color: #78b4dc;
  }
`;

const TrendWrapperPrimaryNoBg = styled.div`
  padding: 0rem 0.5rem;
  display: flex;
  align-items: center;
  border-radius: 0.25rem;
  justify-content: space-between;
  height: 1.5rem;
  font-weight: bold;
  font-size: 0.75rem;

  &:only-child.positive {
    color: #00a050;
  }

  &:only-child.negative {
    color: #c54f4f;
  }

  &:only-child.no-change {
    color: #78b4dc;
  }
`;

const TrendIndicatorPrimary = styled.div`
  margin-right: 0.5rem;
`;

const TrendValuePrimary = styled.div`
  white-space: nowrap;
`;

export const TrendPrimary = (props: any): JSX.Element => (
  <TrendWrapperPrimary className={props.trend}>
    <TrendIndicatorPrimary>
      {props.trend === Trend.Positive && <GreenArrow />}
      {props.trend === Trend.Negative && <RedArrow />}
      {props.trend === Trend.NoChange && <BlueArrow />}
    </TrendIndicatorPrimary>
    <TrendValuePrimary>
      {props.trend === Trend.Positive && '+ '}
      {props.trend === Trend.Negative && '- '}
      <span dangerouslySetInnerHTML={{ __html: `${props.trendPercent} %` }} />
    </TrendValuePrimary>
  </TrendWrapperPrimary>
);

export const TrendPrimaryNoBg = (props: any): JSX.Element => (
  <TrendWrapperPrimaryNoBg className={props.trend}>
    <TrendValuePrimary>{props.trendPercent}</TrendValuePrimary>
  </TrendWrapperPrimaryNoBg>
);

const TrendIndicatorSecondary = styled.div`
  margin-right: 0.5rem;
`;

const TrendValueSecondary = styled.div``;

const TrendWrapperSecondary = styled.div`
  display: flex;
  align-items: center;
  padding: 0 0.5rem 0 0.5rem;
  justify-content: space-between;
  min-width: 4rem;
  font-weight: bold;
  font-size: 0.875rem;

  &:only-child.positive {
    color: #00a050;
  }

  &:only-child.negative {
    color: #c54f4f;
  }

  &:only-child.no-change {
    color: #78b4dc;
  }
`;

export const TrendSecondary = (props: any): JSX.Element => (
  <TrendWrapperSecondary className={props.trend}>
    <TrendIndicatorSecondary>
      {props.trend === Trend.Positive && <GreenArrow />}
      {props.trend === Trend.Negative && <RedArrow />}
      {props.trend === Trend.NoChange && <BlueArrow />}
    </TrendIndicatorSecondary>
    <TrendValueSecondary>
      {props.trend === Trend.Positive && '+ '}
      {props.trend === Trend.Negative && '- '}
      <span dangerouslySetInnerHTML={{ __html: `${props.trendPercent} %` }} />
    </TrendValueSecondary>
  </TrendWrapperSecondary>
);

export const TrendNoArrow = (props: any): JSX.Element => (
  <TrendWrapperSecondary className={props.trend}>
    <TrendValueSecondary style={{ minWidth: '3rem', textAlign: 'right' }}>
      {props.trend === Trend.Positive && '+ '}
      {props.trend === Trend.Negative && '- '}
      <span dangerouslySetInnerHTML={{ __html: `${props.trendPercent} %` }} />
    </TrendValueSecondary>
  </TrendWrapperSecondary>
);
