import React from 'react';
import styled from 'styled-components';

// NOTE: cant export along others for some reason. webpack/ts thing I assume
export type FilterType = {
  [propName: string]: boolean;
};

interface FilterProps {
  filters: FilterType;
  onFilter: Function;
  className?: string;
}

const switchSize = '1.5rem';
const LabelText = styled.span`
  font-size: 0.8125rem;
`;

const ToggleText = styled.span`
  display: flex;
  align-items: center;
  flex-flow: row nowrap;
  font-weight: 500;

  &:before,
  &:after {
    transition: inherit;
  }

  &:before {
    content: attr(data-checked);
    margin-right: 0.5rem;
  }

  &:after {
    content: attr(data-unchecked);
  }
`;

const ToggleMark = styled.span`
  border-radius: 50%;
  background: currentColor;
  height: ${switchSize};
  width: ${switchSize};
`;

const FilterButton = styled.input.attrs({ type: 'checkbox' })`
  appearance: none;
  opacity: 0;
  position: absolute;
  visibility: hidden;
`;

const Toggle = styled.div(
  ({ theme: t }) => `
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  color: ${t.colors.text.ascent};
  background: ${t.colors.background.white};
  border-radius: 100px;
  overflow: hidden;
  padding: .25rem;

  ${ToggleText} {
    padding: 0 .5rem 0 0;
    margin: 0;
    transform: translateX(.5rem);
    transition: transform .35s ease-in,
                color .2s ease-in;

    &:after {
      position: absolute;
      transform: translateX(calc(100% + ${switchSize} + 2rem));
      color: transparent;
    }
  }
  ${FilterButton}:checked + ${ToggleText} {
    transform: translateX(calc(-100% + ${switchSize} + .5rem));
    transition-timing-function: ease-out;

    &:before {
      color: transparent;
    }

    &:after {
      color: inherit;
    }
  }
  @media (min-width: ${t.break.min}) {
    background: ${t.colors.background.secondary};
  }
`,
);

const Filter = styled.label`
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  cursor: pointer;

  ${Toggle} {
    margin-left: 0.5rem;
  }
`;

const Form = styled.form``;

const Filters = ({ onFilter, filters, className }: FilterProps) => (
  <Form className={className}>
    {Object.keys(filters).map((filterKey: string) => {
      const isFilterOn = filters[filterKey];
      return (
        <Filter key={filterKey}>
          <LabelText>Näytä vain</LabelText>
          <Toggle>
            <FilterButton
              onChange={() => {
                onFilter(filterKey);
              }}
              checked={!!isFilterOn}
            />
            <ToggleText data-checked="Inaktiiviset" data-unchecked="Aktiiviset">
              <ToggleMark aria-hidden="true" />
            </ToggleText>
          </Toggle>
        </Filter>
      );
    })}
  </Form>
);

export { Filters as default, Filter };
