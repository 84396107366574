import React, { useState } from 'react';
import styled from 'styled-components';
import { DateRange } from 'react-date-range';
import { fi } from 'date-fns/locale';

import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

import Modal from './Modal';
import { ReactComponent as Close } from '../assets/close.svg';

const style = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    border: 'none',
  },
  overlay: {
    background: 'rgba(0, 0, 0, 0.5)',
    zIndex: 1000,
  },
};

const CloseButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
`;

const CloseButton = styled.div`
  display: flex;
  width: 2rem;
  height: 2rem;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-bottom: 1rem;
`;

const Button = styled.button`
  height: 3rem;
  background-color: #00664f;
  color: #fff;
  border-radius: 0.25rem;
  margin-top: 3rem;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export type Props = {
  dateRange: Object;
  setDateRange: Function;
  setIsOpen: Function;
  isOpen: boolean;
};

var now = new Date();

const defaultDateRangeProps = {
  minDate: new Date('2017'),
  maxDate: new Date(now.getFullYear(), now.getMonth() + 2, 0),
  locale: fi,
  rangeColors: ['#00664f'],
};

const Calendar = ({
  setIsOpen,
  isOpen,
  dateRange,
  setDateRange,
}: Props): JSX.Element => {
  const [state, setState] = useState(dateRange);

  const apply = () => {
    setDateRange(state);
    setIsOpen(false);
  };

  /* NOTE: needs to be ts-ignored as Date prop is f'ckd but there's nothing wrong with the code.
   * Also ts-ignore syntax is strict, so create here due to that
   */
  const dateSelector = (
    // @ts-ignore
    <DateRange
      {...defaultDateRangeProps}
      ranges={[state]}
      onChange={(item: any) => setState(item.selection)}
    />
  );

  return (
    <Modal isOpen={isOpen} style={style}>
      <Wrapper>
        <CloseButtonWrapper>
          <CloseButton
            onClick={() => {
              setIsOpen(false);
            }}
            id="calendar-close"
          >
            <Close />
          </CloseButton>
        </CloseButtonWrapper>
        {dateSelector}
        <Button onClick={apply}>Valitse aikarajaus</Button>
      </Wrapper>
    </Modal>
  );
};

export default Calendar;
