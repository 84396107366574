import React, { useState } from 'react';
import styled from 'styled-components';
import { ReactComponent as LogoSVG } from '../assets/logo.svg';
import { ReactComponent as LogoFullSizeSVG } from '../assets/logo-full-size.svg';
import { ReactComponent as BellSVG } from '../assets/bell.svg';
import { ReactComponent as BellInverseSVG } from '../assets/bell-inverse.svg';
import { ReactComponent as CalendarIcon } from '../assets/calendar.svg';
import { ReactComponent as BackIcon } from '../assets/w-arrow-ascent.svg';
import { Text, TextBold } from '../config/default-styles';
import moment from 'moment';
import ProfileImage, { ProfileImageWithInitalsHeader } from './ProfileImage';
import Calendar from './Calendar';
import { MeterProp, DateRange } from '../types';
import ImpersonatorDisclaimer from './Impersonate/Disclaimer';

const Wrapper = styled.div`
  padding: 0rem;
  background-color: ${props => props.theme.colors.background.primary};

  @media (max-width: ${props => props.theme.break.max}) {
    min-height: 14.25rem;
  }

  @media (min-width: ${props => props.theme.break.min}) {
    &.small {
      min-height: 10.25rem;
    }

    &.large {
      min-height: 14.25rem;
    }
  }
`;

const HeadWrapper = styled.div`
  display: flex;
  flex: 0 1;
  align-items: center;
  justify-content: space-between;
  padding: 0 1rem;
  height: 3rem;

  @media (min-width: ${props => props.theme.break.min}) {
    background-color: ${props => props.theme.colors.background.white};
  }
`;

const BodyWarapper = styled.div`
  @media (min-width: ${props => props.theme.break.min}) {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
  }
`;

const DateRangeBarWrapper = styled.div`
  display: flex;
  flex-direction: row-reverse;
  align-items: baseline;
`;

const CompareDate = styled.div`
  color: ${props => props.theme.colors.text.white};
  @media (max-width: ${props => props.theme.break.max}) {
    display: none;
  }
`;

const Logo = styled(LogoSVG)`
  @media (min-width: ${props => props.theme.break.min}) {
    display: none;
  }
`;

const LogoFullSize = styled(LogoFullSizeSVG)`
  @media (max-width: ${props => props.theme.break.max}) {
    display: none;
  }
`;

const Bell = styled(BellSVG)`
  @media (min-width: ${props => props.theme.break.min}) {
    display: none;
  }
`;
const BellInverse = styled(BellInverseSVG)`
  @media (max-width: ${props => props.theme.break.max}) {
    display: none;
  }
`;

const Alerts = styled.div`
  margin-right: 1.75rem;
`;

const Profile = styled.div`
  align-self: flex-end;
`;

const RightWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-wrap: nowrap;
`;

const DateRangeBar = styled.div`
  margin: 1.25rem 1rem 0 1rem;
  height: 2rem;
  border-radius: 1rem;
  background-color: #186f5d;
  text-align: center;
  color: #fff;
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: 0.75rem;
  letter-spacing: 0.15em;
  text-transform: uppercase;
  padding-right: 1.5rem;
  cursor: pointer;
  flex-grow: 1;
`;

const CalendarWrapper = styled.div`
  margin: 1rem 1rem 1rem 2rem;
`;

const MobileBack = styled.div`
  margin: 1.5rem 0 1rem 1rem;
  font-weight: bold;
  font-size: 0.875rem;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 5.25rem;
  cursor: pointer;
  @media (min-width: ${props => props.theme.break.min}) {
    display: none;
  }
`;

const Heading = styled.div`
  color: #fff;
  font-weight: bold;
  font-size: 1.3125rem;
  margin-top: 1.5rem;
  padding: 0 1rem;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

const HeadingStores = styled.div`
  display: flex;
  font-weight: bold;
  font-size: 12px;
  letter-spacing: 0.15em;
  text-transform: uppercase;
  color: #77aca2;
  margin: 1rem;
  @media (min-width: ${props => props.theme.break.min}) {
    display: none;
  }
`;

const AgencySelectWrap = styled(Heading)`
  position: relative;
`;

// const Label = styled.label`
//   font: inherit;
// `;

const Select = styled.select`
  background: none;
  border: 0;
  cursor: pointer;
  font: inherit;
  font-size: 1rem;
  color: inherit;

  &,
  &:focus {
    outline: none;
  }
`;

export type AgencyProps = {
  agency: MeterProp;
};

type Props = {
  dateRange: DateRange;
  selectedStore: string | undefined;
  setDateRange: Function;
  setSelectedStore: any;
  claims: any;
  image?: string;
  profileImage?: string;
  showStores: boolean; // entrepreneurs
  name?: string; // store, company, or person name
  currentAgency?: string;
  agencies?: null | string[];
  onSelectAgency?: (agency: string) => void;
};

// TODO: make custom select to fix Heading font-size not being used in select in case it becomes an issue
const AgencySelect = ({
  agencies,
  onSelect,
  currentAgency,
}: {
  agencies: string[];
  onSelect: (agency: string) => void;
  currentAgency?: string;
}) => (
  <AgencySelectWrap>
    {/* <Label htmlFor="agency-select">{currentAgency || 'Koko yhtiö'}</Label> */}
    <Select
      id="agency-select"
      onChange={({ target }) => {
        onSelect(target.value);
      }}
      value={currentAgency}
    >
      <option value="">Koko yhtiö</option>
      {agencies.map(agency => (
        <option key={agency} value={agency}>
          {agency}
        </option>
      ))}
    </Select>
  </AgencySelectWrap>
);

const AppHead = ({
  dateRange,
  setDateRange,
  name,
  selectedStore,
  setSelectedStore,
  claims,
  showStores,
  image,
  profileImage,
  agencies,
  onSelectAgency,
  currentAgency,
}: Props) => {
  const { startDate, endDate } = dateRange;

  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <ImpersonatorDisclaimer claims={claims} />
      <Wrapper className={showStores ? 'large' : 'small'}>
        <HeadWrapper>
          <Logo />
          <LogoFullSize />
          <RightWrapper>
            <Alerts>
              <Bell />
              <BellInverse />
            </Alerts>
            <Profile>
              {image ? (
                <ProfileImage image={profileImage} />
              ) : (
                <ProfileImageWithInitalsHeader claims={claims} />
              )}
            </Profile>
          </RightWrapper>
        </HeadWrapper>
        <BodyWarapper>
          <DateRangeBarWrapper>
            <DateRangeBar
              onClick={() => {
                setIsOpen(!isOpen);
              }}
              id="calendar-open"
            >
              <CalendarWrapper>
                <CalendarIcon />
              </CalendarWrapper>
              {moment(startDate).format('DD.MM.YYYY')} -{' '}
              {moment(endDate).format('DD.MM.YYYY')}
            </DateRangeBar>
            <CompareDate>
              <Text>Verrataan </Text>
              <TextBold>
                {moment(dateRange.startDate)
                  .subtract(1, 'year')
                  .format('DD.MM.YYYY')}{' '}
                -{' '}
                {moment(dateRange.endDate)
                  .subtract(1, 'year')
                  .format('DD.MM.YYYY')}
              </TextBold>
            </CompareDate>
          </DateRangeBarWrapper>
          {selectedStore && showStores && (
            <MobileBack
              onClick={() => {
                setSelectedStore(undefined);
              }}
            >
              <BackIcon />
              Takaisin
            </MobileBack>
          )}
          {agencies?.length &&
          onSelectAgency &&
          typeof currentAgency === 'string' ? (
            <AgencySelect
              agencies={agencies}
              onSelect={onSelectAgency}
              currentAgency={currentAgency}
            />
          ) : (
            <Heading>{name}</Heading>
          )}
          {!selectedStore && showStores && (
            <HeadingStores>Myymälät</HeadingStores>
          )}
        </BodyWarapper>
        <Calendar
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          setDateRange={setDateRange}
          dateRange={dateRange}
        />
      </Wrapper>
    </>
  );
};

export default AppHead;
