type AppConfig = {
  AUTH0_CLIENT_ID: string;
  AUTH0_DOMAIN: string;
  QLIK_DOMAIN: string;
  QLIK_PROXY_PREFIX: string;
  QLIK_APP_ID: string;
  SENTRY_DSN?: string;
  SENTRY_ENV: string;
};

const defaults = {
  AUTH0_CLIENT_ID: 'aOh5oRs5XjolJ9UqgLbOHDdFP7W1PZQ7',
  AUTH0_DOMAIN: 'kiinteistomaailma.eu.auth0.com',
  QLIK_DOMAIN: 'analytiikka.kiinteistomaailma.fi',
  QLIK_PROXY_PREFIX: 'auth0-dev',
  QLIK_APP_ID: '97e3d06c-fd99-466d-8cf5-aa2f46d6c990',
  SENTRY_DSN: undefined,
  SENTRY_ENV: 'dev',
};

export const getConfig = (
  hostname: string = window.location.hostname,
): AppConfig => (config[hostname] ? config[hostname] : defaults);

export const config: { [domain: string]: AppConfig } = {
  'kima-dashboard.kiinteistomaailma.fi': {
    ...defaults,
  },
  'dashboard-test.kiinteistomaailma.fi': {
    ...defaults,
    QLIK_PROXY_PREFIX: 'auth0',
    SENTRY_DSN: 'https://b31e7e394bc44630aa389334532804e9@sentry.motley.fi/22',
    SENTRY_ENV: 'staging',
  },
  'dashboard.kiinteistomaailma.fi': {
    ...defaults,
    AUTH0_CLIENT_ID: 'duGYemcyhVKT8zEeUtDfkS8SKzQ71RYg',
    QLIK_PROXY_PREFIX: 'auth0',
    QLIK_DOMAIN: 'arvio.kiinteistomaailma.fi',
    QLIK_APP_ID: '8079ad85-5c9c-4e6c-af6e-40c337bdcdb9',
    SENTRY_DSN: 'https://b31e7e394bc44630aa389334532804e9@sentry.motley.fi/22',
    SENTRY_ENV: 'production',
  },
};

export default getConfig;
