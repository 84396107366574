import styled from 'styled-components';

export const Td = styled.td`
  border-bottom: 1px solid ${props => props.theme.colors.border.primary};
  padding: 0.5rem;
`;

export const TableBlock = styled.div`
  border-radius: 0.5rem;
  background-color: ${props => props.theme.colors.background.white};
  padding: 1rem;
`;

export const Th = styled.th`
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 2;
  text-align: left;
  color: ${props => props.theme.colors.text.primary};
  font-weight: normal;
  border-bottom: 1px solid ${props => props.theme.colors.border.primary};
  background-color: ${props => props.theme.colors.background.white};
  padding: 0.5rem;
`;

export const Tr = styled.tr`
  padding: 0.5rem;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;

  tr:last-child > td {
    border: none;
  }
`;

export const MainViewTableBlock = styled.div`
  border-radius: 0.5rem 0.5rem 0 0;

  table,
  tbody {
    /* height in % within TD doesn't work without parents having height set in % */
    height: 100%;
  }

  thead {
    ${Tr} {
      padding: 0.5rem 1rem;
      background-color: ${props => props.theme.colors.background.white};
    }

    ${Th} {
      &:last-of-type {
        text-align: right;
      }
    }
  }

  tbody {
    ${Tr} {
      padding: 0;
      height: 100%;

      ${Td} {
        padding: 0;
        border: 0;

        /* use universal + :only-child, because we wanna allow Text or whatnot elements as long as they stand alone. */
        > *:only-child {
          padding: 0.5rem 1rem;
          background-color: ${props => props.theme.colors.background.white};
          margin-bottom: 0.3215rem;
          height: calc(100% - 0.3215rem);
        }

        > span:only-child {
          display: flex;
          align-items: center;
        }

        &:last-of-type > span:only-child {
          justify-content: flex-end;
        }
      }

      /* first row gets rounded borders to bottom side only */
      &:first-of-type {
        ${Td}:first-of-type > *:only-child {
          border-radius: 0 0 0 0.5rem;
        }
        ${Td}:last-of-type > *:only-child {
          border-radius: 0 0 0.5rem 0;
        }
      }

      /* the rest of the rows get borders all around */
      &:not(:first-of-type) {
        ${Td}:first-of-type > *:only-child {
          border-radius: 0.5rem 0 0 0.5rem;
        }
        ${Td}:last-of-type > *:only-child {
          border-radius: 0 0.5rem 0.5rem 0;
        }
      }
    }
  }
`;

export default Table;
