import { useState, useEffect } from 'react';

const BASE_KEY = 'kima';
const getKey = (key: string) => `${BASE_KEY}-${key}`;

// set the default start and end dates
const DEFAULT_CALENDAR_OPTIONS = {
  startDate: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
  endDate: new Date(),
  key: 'selection',
};

// save the calendar state in the session storage
// state resets on browser tab close
export function usePersistedCalendarState(key: string): any {
  const [state, setState] = useState(() => {
    const item = sessionStorage.getItem(getKey(key));
    if (item) {
      const obj = JSON.parse(item);
      return {
        ...obj,
        startDate: new Date(obj.startDate),
        endDate: new Date(obj.endDate),
      };
    }
    return DEFAULT_CALENDAR_OPTIONS;
  });
  useEffect(() => {
    sessionStorage.setItem(getKey(key), JSON.stringify(state));
  }, [key, state]);
  return [state, setState];
}

export function usePersistedState<T>(
  key: string,
  defaultValue: any,
): [T, Function] {
  const [state, setState] = useState(() => {
    const item = sessionStorage.getItem(getKey(key));
    if (item) {
      if (item === 'undefined') {
        return undefined;
      }
      return JSON.parse(item);
    }
    return defaultValue;
  });
  useEffect(() => {
    sessionStorage.setItem(getKey(key), JSON.stringify(state));
  }, [key, state]);
  return [state, setState];
}
