// application wide used
// universal types

export type MeterProp = {
  text: string;
  num: number;
};

// TODO: overlap with MeterProp on PartialType. refactor
export type ValueType = {
  num: 'NaN' | number;
  text: string;
};

export type Zip = {
  zip: string;
  name: string;
  city: string;
};

export type GroupedZips = {
  [name: string]: Zip[];
};

export type DateRange = {
  startDate: Date;
  endDate: Date;
  key: string;
};

export enum SortDir {
  asc = 'ASC',
  desc = 'DESC',
}

export enum SortingType {
  numeric = 'numeric',
  alphabetical = 'alphabetical',
}

export type SortType = {
  key: string;
  dir: SortDir;
  sortBy: SortingType;
};

export enum Status {
  Draft = 'luonnos',
  UnsignedDraft = 'allekirjoittamaton luonnos',
  Onhold = 'tauolla',
  Reserved = 'varattu',
  Onsale = 'myytävissä',
  Onrent = 'vuokrattavissa',
  Sold = 'myyty',
  Ended = 'päättynyt',
  Rented = 'vuokrattu',
}

export enum Levels {
  Ok = 'ok',
  Warning = 'warning',
  Disabled = 'disabled',
}
