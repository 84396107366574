import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  padding: 1rem;
  background-color: ${props => props.theme.colors.background.secondary};
`;

const ChildWrapper = styled.div`
  margin-top: -3rem;
`;

type Props = {
  children: any;
};

const AppBody = (props: Props): JSX.Element => (
  <Wrapper>
    <ChildWrapper>{props.children}</ChildWrapper>
  </Wrapper>
);

export default AppBody;
